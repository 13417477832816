<template>
	<div id="body">
		<div class="fatherBox">
      <el-table :data="schoolInfos" v-loading="loading">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="create_time" label="日期" align="center" ></el-table-column>
        <el-table-column prop="ip"      label="IP"  align="center" ></el-table-column>
        <el-table-column prop="ip_addr" label="操作地址" align="center" ></el-table-column>
        <el-table-column prop="change_rs" label="操作内容" align="center" ></el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		schoolLog,
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				schoolInfos: [], // 健康列表数据
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				loading: false, //加载框
			}
		},
		mounted() {
			this.schoolList({}, 1); // 健康列表
		},
		methods: {
			schoolList(serchJson, currpage) { // 健康列表
				this.loading = true;
				const data = {
          page: currpage, // 当前页数
          limit: this.pagesize, // 总数
        };
				schoolLog(data).then(res => {
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.schoolInfos = res.data.list // 健康列表
					this.currpage = currpage //分页显示当前页
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.schoolList({}, val)
				// this.currpage = val
			}
		}
	}
</script>

<style lang="scss" scoped>
	#body {
		// height: 100%;
		// background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			justify-content: space-between;
			border-bottom: 2px solid #e9e9e9;

			p {
				font-size: 16px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #000000;
				margin-left: 32px;
			}

			.btn {
				display: flex;
				align-items: center;
				width: 109px;
				height: 32px;
				opacity: 1;
				border-radius: 2px;
			}
		}

		.query {
			width: 98%;
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			padding-left: 32px;

			.queryLeft {
				width: 75%;
				display: flex;
				flex-wrap: wrap;

				div {
					width: 300px;
					line-height: 50px;
					text-align: left;

					.input,
					.select {
						width: 60%;
					}
				}
			}
		}
	}
</style>
