import request from '@/api/request.js'
// 班级数据列表
export function classDataList (data) {
  return request.post('/school/classList', data)
}
// 数据管理 班级数据
export function classListData (data) {
  return request.post('/school/classListData', data)
}
// 全校全区近视率
export function statistics (data) {
  return request.post('/school/statistics', data)
}
// 全校近视率-折线图
export function schoolGoodPic (data) {
  return request.post('/school/schoolGoodPic', data)
}
// 班级数据-学生列表
export function classStudentsDataList (data) {
  return request.post('/school/classStuEyeList', data)
}
// 班级近视率-折线图
export function classGoodPic (data) {
  return request.post('/school/classGoodPic', data)
}
// 班级数据-学生数据详情
export function stuEyeSearch (data) {
  return request.post('/school/stuEyeSearch', data)
}
// 班级数据-学生数据详情-导出
export function excelDaochueyeDays (data) {
  return request.post('/school/excelDaochueyeDays', data)
}
// 班级数据-学生数据详情-导出word
export function excelDaochueyeWord (data) {
  return request.post('/school/excelDaochueyeWord', data)
}
// 班级数据--导出
export function excelDaochueye (data) {
  return request.post('/school/excelDaochueye', data)
}
// 历史数据创建时间
export function classHistoryCtime (data) {
  return request.post('/school/classHistoryCtime', data)
}
// 历史数据毕业班号
export function classHistoryYear (data) {
  return request.post('/school/classHistoryYear', data)
}
// 历史数据毕业班列表
export function classHistory (data) {
  return request.post('/school/classHistory', data)
}
// 历史数据毕业班详情
export function classHistoryDetail (data) {
  return request.post('/school/classHistoryDetail', data)
}
// 历史数据毕业班导出
export function overClassSearch (data) {
  return request.post('/school/overClassSearch', data)
}
//学校测温数据
export function gradeListss(data){
	return request.post('temperature/gradeList',data)
}
//学生体温详情
export function gradeListDetail(data){
	return request.post("temperature/gradeListDetail",data)
}
//学生检测详情
export function gradeListDetailNicety(data){
	return request.post("temperature/gradeListDetailNicety",data)
}
//年级班级查询
// export function classTypeInfo(data){
// 	return request.post("school/classTypeInfo",data)
// }


