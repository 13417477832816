<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox" v-model="ClassInfos">
        <p>{{grade_name}}{{class_name}}{{student_name }}</p>
		
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
      </div>
     
      <el-table :data="ClassInfos" v-loading="loading">
        <el-table-column label="序号" type="index" width="auto" align="center" ></el-table-column>
        <el-table-column label="测温时间" prop="create_date" width="auto" align="center" ></el-table-column>
        <el-table-column label="环境温度" prop="env_degree" width="auto" align="center" >
			<template slot-scope="scope">
			   <span >{{scope.row.env_degree}}℃</span>
			 </template>
		</el-table-column>
        <el-table-column label="检测温度" prop="person_degree" width="auto" align="center" >
			<template slot-scope="scope">
			   <span >{{scope.row.person_degree}}℃</span>
			 </template>
		</el-table-column>
        <el-table-column label="测温照片" prop="then_photo" width="auto" align="center">
					<template slot-scope="scope">
						<el-image style="width: 160px; height: 90px" alt="测温照片" :src="scope.row.then_photo"
							:preview-src-list="[scope.row.then_photo]"  slot="placeholder">
							<div style=" margin-top: 36px;" slot="error" class="image-slot">
							        --
							 </div>
						</el-image>
					</template>
				</el-table-column>
       
        
      </el-table>
      <div v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
    
  </div>
</template>

<script>
import Vue from 'vue'
import {
  gradeListDetailNicety
} from '@/api/SchoolApi.js'

// import {
//   mapGetters
// } from 'vuex'
export default {
  // computed: {
  //   ...mapGetters(['Status'])
  // },
  data () {
    return {
		student_id: this.$route.params.student_id,
		student_name:this.$route.params.student_name,
		grade_name:this.$route.params.grade_name,
		class_name:this.$route.params.class_name,
		ClassInfoss:{},
      ClassInfos: [],
      loading: false, // 加载框
      pagesize: 10, // 每页显示条目个数
      currpage: 1, // 初始页
      CountNum: 0, // 总条数
      gradeid: '', // 年级查询id
      gradeLists: [], // 年级数组
      classid: '', // 班级查询id
      classList: [], // 班级数组
      dialogVisible: false,
      dialogVisibles: false,
      daterange: [],
      form: {
        day: '',
        gid: '', // 年级查询id
        cid: '', // 班级查询id
        bstatus: 1
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
     
	
    }
  },
  mounted () {
    this.ClassList(1) // 获取数据列表
    // this.gradeListvue() // 获取年级信息
    // this.DataList() // 统计数据
  },
  methods: {
    reset () { // 重置按钮
      this.classList = []
      this.gradeid = ''
      this.currpage = 1
      this.ClassList(this.currpage)
    },
    ClassList (currpage) { // 班级数据管理列表
      this.loading = true
      const data = {
        page: currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        student_name:this.student_name,
        student_id: this.student_id
      }
      gradeListDetailNicety(data).then(res => {
		
        this.ClassInfos = res.data.list
        this.CountNum = parseInt(res.data.countNum) // 总条数
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    
    
    
    handleCurrentChange (val) {
      if (this.gradeid == '') {
        this.ClassList(val)
        this.currpage = val
      } else {
        this.classListDataSearch(val) // 当有姓名条件时 分页操作
      }
    },
    classListDataSearchfirst (val) { // 只有当点击查询时候走这个方法
      const data = {
        page: val,
        limit: this.pagesize,  
        grade: this.gradeid
      }
      gradeListDetailNicety(data).then(res => {
        this.currpage = 1
        this.ClassInfos =res.data.list
        this.CountNum = parseInt(res.data.countNum)
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },

    // classListDataSearch (val) { // 当第一次 点了查询条件后 分页走这个方法
    //   this.currpage = val
    //   const data = {
    //     page: this.currpage, // 当前页数
    //     limit: this.pagesize, //	每页条数
    //     grade: this.gradeid
    //   }

    //   gradeListDetail(data).then(res => {
    //     this.ClassInfos = res.data.list
    //     this.CountNum = parseInt(res.data.countNum)
    //     this.loading = false
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
   
  
    // doExport () { // 导出
    //   if (!this.form.day) {
    //     this.$message.error('请选择日期！')
    //     return false
    //   }

    //   excelDaochueye(this.form).then(res => {
    //     if (res.status.code == 1) {
    //       window.open(Vue.prototype.$url + res.data)
    //       this.dialogVisibles = false
    //     } else {
    //       this.$message.error(res.status.msg)
    //     }
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
  //   gradeListvue () { // 年级列表
  //     const data = {
  //       type: 1 // 没用
  //     }
  //     gradeListDetailNicety(data).then(res => {
  //       this.gradeLists = res.data.list
		// console.log(res.data.list)
		// console.log(this.gradeLists)
  //     }).catch(err => {
  //       console.log(err)
  //     })
  //   },
    // classSelect () { // 班级列表
    //   this.form.cid = ''
    //   this.classList = []
    //   const data = {
    //     gid: this.form.gid
    //   }

    //   if (this.gid != '') {
    //     gradeClass(data).then(res => {
    //       this.classList = res.data
    //     }).catch(err => {
    //       console.log(err)
    //     })
    //   }
    // },
    //

  }

}
</script>

<style lang="scss" scoped>
  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
  .dataBox{
    padding:5px 20px;
    font-size:18px;
    cursor:pointer;
    .num{
      color:#409EFF
    }
  }
  .dataBox1{
    padding:5px 20px;
    font-size:18px;
    .num{
      color:#409EFF
    }
  }

</style>
