<template>
	<div>
		<div class="table">
			<div class="info">
				<span>
					<span class="back" @click="$router.go(-1)">学生列表</span>
					/ 家长实名列表
				</span>
				<span>家长人数： {{CountNum}}</span>
			</div>
			<el-table :data="data" v-loading="loading">
				<el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
				<el-table-column prop="authname" label="家长姓名" align="center"></el-table-column>
				<el-table-column prop="RoleName" label="与学生关系" align="center"></el-table-column>
				<el-table-column label="照片" align="center">
					<template slot-scope="scope">
						<el-image v-if="scope.row.facepic" style="width: 160px; height: 90px" fit="contain" alt="认证照片" :src="scope.row.facepic"
							:preview-src-list="[scope.row.facepic]"></el-image>
						<span v-else>未认证</span>
					</template>
				</el-table-column>
				<el-table-column prop="createtime" label="认证时间" align="center"></el-table-column>
			</el-table>
			<div>
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		authGuaList
	} from '@/api/TeacherApi.js'
	export default {
		data() {
			return {
				data: [], //家长数据
				studentId: this.$route.params.studentId, //学生id
				loading: false, //加载框
				CountNum: 0, // 家长总数
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
			}
		},
		created() {
			this.getData(1); //获取家长实名列表
		},
		methods: {
			async getData(currpage) { //获取家长实名列表
				this.loading = true;
				const data = {
					stuUserID: this.studentId,
					page: currpage, // 当前页数
					limit: this.pagesize //	每页条数
				};
				let res = await authGuaList(data); //接口请求
				this.data = res.data.List;	//数据
				this.CountNum = parseInt(res.data.CountNum); // 家长总数
				this.currpage = currpage;
				this.loading = false;
			},
			handleCurrentChange(val) {	//分页
				this.getData(val);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		padding: 20px 50px;

		.info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 20px;
			font-weight: bold;
			line-height: 50px;
			padding-right: 100px;

			.back {
				color: #79740E;
				cursor: pointer;
			}
		}
	}
</style>
