<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>学校数据管理</p>
      </div>
      <div class="ClassInfo_box">

        <div class="chartTitle">

          <ul>
            <li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index,item)"
              :class="currentIndex == index ? 'active' : ''" style="margin-top: 16px;">
              近{{ item }}天
            </li>
            <li style="margin-top: 10px;">
              <el-date-picker v-model="searchData" type="daterange" size="small" @change="changeDate($event)"
                :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                align="right" value-format="timestamp">
              </el-date-picker>

            </li>
            <li style="margin-top: 6px;">
              <div style="width: 300px; float: left">
                设备类型：
                <el-select v-model="select_value" class="select" clearable>
                  <el-option v-for="item in equipment_type" :key="item.id" :label="item.title" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li style="margin-top: 6px;">
              <div class="queryRight">
                <el-button type="primary" @click="searchStu(5)">查询</el-button>
              </div>
            </li>
          </ul>

        </div>
      </div>

      <!-- 音视频 -->
      <div v-if="equipment_value == 6">
        <el-table :data="school_list" border style="width: 100%">
          <el-table-column prop="date" label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="SchoolName" label="学校" align="center"></el-table-column>
          <el-table-column prop="ptel_num" label="普通电话次数"></el-table-column>
          <el-table-column prop="ptel_time" label="普通电话时长"></el-table-column>
          <el-table-column prop="ytel_num" label="语音通话次数"></el-table-column>
          <el-table-column prop="ytel_time" label="语音电话时长"></el-table-column>
          <el-table-column prop="stel_num" label="视频通话次数"></el-table-column>
          <el-table-column prop="stel_time" label="视频通话时长"></el-table-column>

          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">

              <el-button size="mini" type="primary" plain @click="detail(scope.row.school_id,scope.row.SchoolName)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>

      </div>
      <!-- 视力测试 -->
      <div v-else-if="equipment_value == 1">
        <el-table :data="school_list" border style="width: 100%">
          <el-table-column prop="date" label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="schoolname" label="学校"></el-table-column>
          <el-table-column prop="jsum" label="近视数"></el-table-column>
          <el-table-column prop="jsl" label="近视率"></el-table-column>
          <!-- <el-table-column prop="ssum" label="测试总数"></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="100" key="slot">
            <template #default="scope">

              <el-button size="mini" type="primary" plain @click="detail(scope.row.SchoolID,scope.row.schoolname)">详情
              </el-button>

            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="total,prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>

      </div>

      <!-- 智能电脑验光仪 -->
      <div v-else-if="equipment_value== 5">
        <el-table :data="school_list" border style="width: 100%">
          <el-table-column prop="index" label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="schoolname" label="学校" align="center"></el-table-column>
          <el-table-column prop="ssum" label="检测人数"></el-table-column>
          <!-- <el-table-column prop="ssum" label="检测近视率"></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="100" key="slot">
            <template #default="scope">
              <el-button size="mini" type="primary" plain @click="detail(scope.row.schoolid, scope.row.schoolname)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>
      </div>
      <!-- 校园卫士 -->
      <div v-else-if="equipment_value == 4">
        <el-table :data="school_list" border style="width: 100%">
          <el-table-column prop="date" label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="SchoolName" label="学校" align="center"></el-table-column>
          <el-table-column prop="jnum" label="进校人数"></el-table-column>
          <el-table-column prop="cnum" label="出校人数"></el-table-column>
          <el-table-column prop="ynum" label="异常人数"></el-table-column>
          <el-table-column prop="tnum" label="推送打开率"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100" key="slot">
            <template #default="scope">
              <el-button size="mini" type="primary" plain @click="detail(scope.row.schoolid, scope.row.SchoolName)">详情
              </el-button>
            </template>
          </el-table-column>

        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import {
    machine_details_video_school_day,
    machine_details_eye_school_day,
    machine_details_camera_school_day,
    machine_details_ygy_school_day
  } from '@/api/agent_newApi.js'
  export default {
    data() {
      return {
        curr_page: 1, // 初始页
        page_size: 10, // 每页显示条目个数
        count_num: 0, // 总条数
        ygy_list: [], //验光仪
        video_list: [], //音视频列表
        eye_list: [], //视力列表
        camera_list: [], //校园卫士
        school_list: [], //数据列表
        equipment_type: [{
            id: 1,
            title: '视力检测设备'
          },
          {
            id: 4,
            title: '校园卫士'
          }, {
            id: 5,
            title: '智能电脑验光仪'
          }, {
            id: 6,
            title: '音视频设备'
          },
          // {
          //   id: 7,
          //   title: '训练仪设备'
          // },
          // {
          //   id: 8,
          //   title: '签到设备'
          // }
        ],
        select_value: 1,
        equipment_value: 1,
        dateList: ['3', '7', '30'], // 选项
        currentIndex: 0,
        dataType: 2, //搜索类型
        searchData: [], //搜索时间
        postData: [],
        pickerOptions: {
          // 设置不能选择的日期
          onPick: ({
            maxDate,
            minDate
          }) => {
            this.choiceDate0 = minDate.getTime();
            if (maxDate) {
              this.choiceDate0 = '';
            }
          },
          disabledDate: (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime();
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            const max = newDate < maxTime ? newDate : maxTime;
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            if (this.choiceDate0) {
              return time.getTime() < min || time.getTime() > max;
            }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate;
          }
        },
      }
    },
    created() {

      if (localStorage.getItem('school_curr_page') != null) {

        this.curr_page = parseInt(localStorage.getItem('school_curr_page'));
        localStorage.setItem('school_curr_page', null)
      }
      if (localStorage.getItem('school_equipment_value') != null && !isNaN(localStorage.getItem('school_equipment_value'))) {

        this.equipment_value = parseInt(localStorage.getItem('school_equipment_value'));
        this.select_value = parseInt(localStorage.getItem('school_equipment_value'));
        this.dataType = parseInt(localStorage.getItem('school_dataType'));
        localStorage.setItem('school_equipment_value', null)
      }
      if (localStorage.getItem('school_dataType') != null && !isNaN(localStorage.getItem('school_dataType'))) {
        this.dataType = parseInt(localStorage.getItem('school_dataType'));
        localStorage.setItem('school_dataType', null)
      }
      if (localStorage.getItem('school_currentIndex') != null && !isNaN(localStorage.getItem('school_currentIndex'))) {
        this.currentIndex = parseInt(localStorage.getItem('school_currentIndex'));
        localStorage.setItem('school_currentIndex', null)
      }

      if (localStorage.getItem('school_startDate') != null || localStorage.getItem('school_endDate') != null) {
        this.postData.push(parseInt(localStorage.getItem('school_startDate')));
        this.postData.push(parseInt(localStorage.getItem('school_endDate')));
      }

    },
    mounted() {
      this.get_school_list(this.curr_page, this.currentIndex)
    },
    methods: {
      select_type(val) {
        this.select_value = val;

      },
      searchStu() {
        this.equipment_value = this.select_value;

        this.curr_page = 1;
        this.school_list = [];
        this.get_school_list(1, this.currentIndex);
      },
      //获取列表
      get_school_list(curr_page, current_index) {
        const data = {
          limit: this.page_size,
          page: curr_page,
          datetype: this.dataType,
          startDate: Math.floor(this.postData[0] / 1000), //开始时间 时间戳
          endDate: Math.floor(this.postData[1] / 1000), //结束时间 时间戳
        };

        if (this.equipment_value == 6) {

          machine_details_video_school_day(data).then(res => {
            if (res.status.code == 1) {

              this.school_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false

            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })

        } else if (this.equipment_value == 1) {

          machine_details_eye_school_day(data).then(res => {
            if (res.status.code == 1) {

              this.school_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })
        } else if (this.equipment_value == 4) {
          machine_details_camera_school_day(data).then(res => {
            if (res.status.code == 1) {

              this.school_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })
        } else if (this.equipment_value == 5) {
          machine_details_ygy_school_day(data).then(res => {
            if (res.status.code == 1) {

              this.school_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })
        }
        //在分页里面加入 :current-page.sync="curr_page" 使第一次查询时 分页在第一页
        this.curr_page = curr_page
        this.currentIndex = current_index
      },
      handleVideoChange(val) {
        this.curr_page = val
        this.get_school_list(val, this.currentIndex);

      },
      Atpitch(index, item) { // 折线图时间选择索引
        this.searchData = '';
        this.currentIndex = index

        switch (index) {
          case 0:
            this.dataType = 2;
            this.doThis(3);
            break
          case 1:
            this.dataType = 3;
            this.doThis(7);
            break
          case 2:
            this.dataType = 4;
            this.doThis(30);
            break

          default:
            this.dataType = 2;
            this.doThis(3);

        }
      },
      //选择时间
      doThis(num) {
        this.postData = [];
        let new_date = new Date(); //获取系统当前时间
        let start_date = (new_date.getTime()).toFixed(0);
        let end_date = (new Date(new_date.getTime() - num * 24 * 60 * 60 * 1000).getTime()).toFixed(0);
        this.postData.push(parseInt(start_date));
        this.postData.push(parseInt(end_date));
      },
      //时间插件
      changeDate(val) {
        this.currentIndex = -1
        this.postData = [];
        this.postData.push(this.searchData[0]);
        this.postData.push(this.searchData[1]);

        this.dataType = 5;
      },
      //跳转
      detail(school_id, school_title) {
        localStorage.setItem('Route', 'school_management') // 存跳转标识
        localStorage.setItem('school_curr_page', this.curr_page) // 存跳转分页面
        localStorage.setItem('school_equipment_value', this.equipment_value) // 存类型
        localStorage.setItem('school_currentIndex', this.currentIndex) // currentIndex
        localStorage.setItem('school_school_title', school_title) // school_title
        localStorage.setItem('school_dataType', this.dataType) // datetype
        localStorage.setItem('school_startDate', Math.floor(this.searchData[0] / 1000)) //开始时间 时间戳
        localStorage.setItem('school_endDate', Math.floor(this.searchData[1] / 1000)) //结束时间 时间戳
        this.$router.push({
          name: 'school_management_detail',
          params: {
            school_id: school_id,
            type: this.equipment_value
          }
        })
      },
    }

  }

</script>
<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;
    margin-bottom: 20px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      // border-bottom:1px solid #cccccc ;
      .head_dates {
        display: inherit;
        margin-left: 102px;
        font-weight: bold;
      }

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-left: 54px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    color: #1890FF;
    font-size: 14px;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }

</style>
