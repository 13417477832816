<template>
	<div>
		<div class="table">
			<div class="info">
				<span>
					<span class="back" @click="$router.go(-1)">学校应用假日列表</span> / 假日模板列表
				</span>
				<el-button type="primary" plain @click="add">添加</el-button>
			</div>
			<el-table :data="templateData" v-loading="loading">
				<el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
				<el-table-column prop="tplname" label="模板名称" align="center"></el-table-column>
				<el-table-column label="上学天数" :formatter="goSchoolFormatter" align="center"></el-table-column>
				<el-table-column prop="" label="操作" align="center" width="150">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: space-around;">
							<el-button type="warning" plain size="mini" @click="update(scope.row)">修改</el-button>
							<el-button type="danger" plain size="mini" @click="deleteClick(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
				<template slot="empty">
					<el-button type="text" @click="add">暂无模板，请添加模板</el-button>
				</template>
			</el-table>
		</div>
		<el-dialog title="确定删除模板吗？" center :visible.sync="deleteDialog" width="400px">
			<div slot="footer">
				<el-button @click="deleteDialog = false">取 消</el-button>
				<el-button type="primary" @click="deleteConfirm()">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="templateDialog" :close-on-click-modal="false" width="900px" top="50px">
			<div slot="title">
				{{type + '假日模板'}}
			</div>
			<div class="holiday-template">
				<div class="template-name">
					<span class="name">模板名称：</span>
					<el-input class="input" v-model="templateName" placeholder="请输入模板名称" :maxlength="24"></el-input>
					<span>*小于12个汉字或24个字符</span>
					<el-button class="button" @click="affirm">{{type}}</el-button>
				</div>
				<div class="template-date">
					<span class="select-date">日期选择：</span>
					<div class="select-week">
						<div class="week" v-for="(item,index) in week" :key="index" :class="item.active ? 'active' : ''"
							@click="weekClick(index)">{{item.value}}</div>
					</div>
				</div>
				<div class="template-calendar">
					<div class="months" v-for="(item,index) in calendarData" :key="index">
						<div class="header">
							{{item.month}}
						</div>
						<div class="week">
							<div v-for="(v,i) in week" :key="i">{{v.value.slice(-1)}}</div>
						</div>
						<div class="month">
							<div v-for="(val,x) in item.list" :key="x">
								<div class="day" v-if="val.is" :class="selectList.includes(val.day) ? 'active' : ''"
									:style="val.week == 0 || val.week == 6 ? 'color:red;' : ''"
									@click="dayClick(val.day)">
									{{val.day.slice(-2,-1) == '0' ? val.day.slice(-1) : val.day.slice(-2)}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		tplList,
		saveTemplate,
		delTpl
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				templateData: [], //模板数据
				calendarData: [], //当年日期数据
				selectList: [], //选择的日期数据
				week: [{
					value: '星期日',
					active: false,
					name: 0
				}, { // 选择的星期
					value: '星期一',
					active: false,
					name: 1
				}, {
					value: '星期二',
					active: false,
					name: 2
				}, {
					value: '星期三',
					active: false,
					name: 3
				}, {
					value: '星期四',
					active: false,
					name: 4
				}, {
					value: '星期五',
					active: false,
					name: 5
				}, {
					value: '星期六',
					active: false,
					name: 6
				}],
				templateName: '', //添加或修改时模板名称
				type: '完成', //假日模板类型
				tplId: '', //选择修改的模板id
				delId: '', //选择删除的模板id
				loading: false, //加载框
				templateDialog: false, //添加模板弹框
				deleteDialog: false, //删除的弹框
			}
		},
		created() {
			this.getTemplateList(); // 获取节假日模板列表
			this.getCalendar(); //获取当前全年日期
		},
		methods: {
			async getTemplateList() { //获取节假日模板列表
				this.loading = true;
				let res = await tplList();
				this.templateData = res.data.list;
				this.loading = false;
			},
			async affirm() { //点击模板完成按钮
				if (!this.templateName) {
					this.templateName = '';
					this.$message.error('模板名称不能为空');
				} else if (this.selectList.length < 1) { //假日模板日期选择低于一条
					this.$message.error('请完善假日模板的日期选择');
				} else {
					let list = [...new Set(this.selectList)];	//去掉修改时重复的日期
					const data = { //携带的数据
						dates: list.join(),
						type: 2,
						tplname: this.templateName,
						tplId: !!this.tplId ? this.tplId : ''
					};
					let res = await saveTemplate(data); //接口请求
					if (res.status.code == 1) {
						this.$message.success('模板' + this.type + '成功');
						this.templateDialog = false; //关闭弹窗
						this.getTemplateList(); // 获取节假日模板列表
					} else {
						this.$message.error(res.status.msg);
					}
				}
			},
			goSchoolFormatter(row) {
				return row.dates.split(',').length + '天';
			},
			add() { //点击添加按钮
				this.type = '添加';
				this.tplId = '';
				this.selectList = [];
				this.templateName = '';
				this.week.forEach(item => item.active = false);
				this.templateDialog = true;
			},
			update(row) { //点击修改按钮
				this.type = '修改';
				this.tplId = row.id;
				this.templateName = row.tplname;
				this.selectList = row.dates.split(',');
				this.templateDialog = true; //打开假日模板弹框
			},
			deleteClick(row) { //点击删除按钮
				this.delId = row.id;
				this.deleteDialog = true; //打开修改弹框
			},
			async deleteConfirm() { //删除模板
				const data = {
					tplId: this.delId
				};
				let res = await delTpl(data); //接口请求
				if (res.status.code == 1) {
					this.deleteDialog = false;
					this.$message.success('模板删除成功');
					this.getTemplateList(); // 获取节假日模板列表
				} else {
					this.$message.error(res.status.msg);
				}
			},
			weekClick(index) { //点击星期
				this.week[index].active = !this.week[index].active; //选中状态
				let week = this.week[index].name; //点击的星期数
				let list = [];
				this.calendarData.forEach(item => { //筛选选中星期的日期数据
					let l = [];
					item.list.forEach(item => {
						if (item.week == week) {
							l.push(item.day);
						}
					});
					list = list.concat(l);
				})
				if (this.week[index].active) { //选中
					this.selectList = this.selectList.concat(list); //存储符合条件的数据
				} else { //取消选中
					this.selectList = this.selectList.filter(item => !list.includes(item)); //去除符合条件的数据
				}
			},
			dayClick(day) { //点击日期
				let index = this.selectList.indexOf(day); //查找有没有当前选中的日期
				if (index != -1) { //有
					this.selectList.splice(index, 1); //删除选中的日期
				} else { //没有
					this.selectList.push(day); //添加选中的日期
				}
			},
			getCalendar() { //生成当年的全部日期
				let year = new Date().getFullYear(); //获取当年年份
				for (let month = 1; month <= 12; month++) {
					let data = { //每个月日期数据
						month: year + '年' + month + '月',
						list: []
					}
					let days = new Date(year, month, 0).getDate(); //返回当月共有多少天
					for (let day = 1; day <= days; day++) {
						let week = new Date(year, month - 1, day).getDay(); //获取当天是星期几
						let m = month < 10 ? ('0' + month) : month; //增加月份前面的0
						let d = day < 10 ? ('0' + day) : day; //增加日期前面的0
						let o = { //每个月每一天数据
							day: year + '-' + m + '-' + d,
							week: week,
							is: true
						};
						data.list.push(o);
					}
					this.calendarData.push(data);
				}
				for (let i = 0; i < 12; i++) { //每月第一天不是星期日的添加空数据，方便循环
					let week = this.calendarData[i].list[0].week;
					for (let x = 0; x < week; x++) {
						this.calendarData[i].list.unshift({
							is: false
						});
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		padding: 20px 50px;

		.info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 20px;
			font-weight: bold;
			line-height: 50px;

			.back {
				color: #79740E;
				cursor: pointer;
			}
		}
	}

	.holiday-template {
		.template-name {
			display: flex;
			align-items: center;
			position: relative;

			.button {
				position: absolute;
				right: 0;
				background: #33CC33;
				color: #FFFFFF;
			}

			.name {
				font-size: 18px;
				font-weight: bold;
			}

			.input {
				width: 300px;
				margin: 0 20px;
			}
		}

		.template-date {
			display: flex;
			align-items: center;
			line-height: 50px;

			.select-date {
				font-size: 18px;
				font-weight: bold;
				margin-right: 20px;
			}

			.select-week {
				display: flex;
				align-items: center;

				.week {
					width: 60px;
					line-height: 30px;
					margin-right: 10px;
					cursor: pointer;
				}

				.active {
					background: #33CC33;
					color: #FFFFFF;
				}
			}
		}

		.template-calendar {
			display: grid;
			grid-template-columns: repeat(4, auto);
			grid-template-rows: repeat(3, auto);
			grid-gap: 20px;

			.months {
				border: 1px solid #33CC33;
				background: #F2F6FC;

				.header {
					line-height: 30px;
					background: #33CC33;
					color: #FFFFFF;
				}

				.week {
					line-height: 25px;
					display: grid;
					grid-template-columns: repeat(7, 1fr);
				}

				.month {
					line-height: 20px;
					display: grid;
					grid-template-columns: repeat(7, 1fr);
					grid-template-rows: repeat(5, 20px);
					grid-gap: 2px;

					.day {
						cursor: pointer;
					}

					.active {
						background: #33CC33;
						color: #FFFFFF !important;
					}
				}
			}
		}
	}
</style>
