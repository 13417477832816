<template>
    <div id="body">
        <el-breadcrumb separator-class="el-icon-arrow-right">
<!--            <el-breadcrumb-item :to="{ path: '/SchoolHome' }"
                >首页</el-breadcrumb-item
            > -->
            <el-breadcrumb-item :to="{ path: '/set_user' }"
                >设置</el-breadcrumb-item
            >
            <el-breadcrumb-item>操作记录</el-breadcrumb-item>
        </el-breadcrumb>
        <br /><br />
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="登录日志" name="first">
                <el-table :data="loginLogData" style="width: 100%">
                    <el-table-column prop="login_time" label="时间" width="200">
                    </el-table-column>
                    <el-table-column prop="ip" label="登录IP" width="200">
                    </el-table-column>
                    <el-table-column prop="ip_addr" label="登录地址">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="班级日志" name="second" v-if="Status=='school'">
                <el-table :data="classLogData" style="width: 100%">
                    <el-table-column prop="ip" label="IP" width="200">
                    </el-table-column>
                    <el-table-column prop="ip_addr" label="IP地址" width="200">
                    </el-table-column>
                    <el-table-column
                        prop="create_time"
                        label="操作时间"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column prop="change_p" label="改变人" width="200">
                    </el-table-column>
                    <el-table-column prop="change_q" label="改变前" width="200">
                    </el-table-column>
                    <el-table-column prop="change_rs" label="改变结果">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="学生日志" name="third">
                <el-table :data="stuLogData" style="width: 100%">
                    <el-table-column prop="ip" label="IP" width="200">
                    </el-table-column>
                    <el-table-column prop="ip_addr" label="IP地址" width="200">
                    </el-table-column>
                    <el-table-column
                        prop="create_time"
                        label="操作时间"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="change_qian"
                        label="改变前"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column prop="change_hou" label="改变后">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="视力日志" name="fourth">
                <el-table :data="healthLogData" style="width: 100%">
                    <el-table-column prop="ip" label="IP" width="200">
                    </el-table-column>
                    <el-table-column prop="ip_addr" label="IP地址" width="200">
                    </el-table-column>
                    <el-table-column
                        prop="create_time"
                        label="操作时间"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column prop="resault" label="改变结果">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
    schoolLogList,
    classLogList,
    stuLogList,
    healthLogList,
} from "@/api/setUser.js";
  import {
    mapGetters
  } from "vuex";

export default {
    props: ["isCollapse"],
    data() {
        return {
            activeName: "first",
            loginLogData: [],
            classLogData: [],
            stuLogData: [],
            healthLogData: [],
        };
    },
    computed: {
      ...mapGetters(["Status"]),
    },
    mounted() {
        this.schoolLogList();
        this.classLogList();
        this.stuLogList();
        this.healthLogList();
    },
    methods: {
        // 登录日志
        schoolLogList() {
            var data = {};
            data.page = 1;
            data.limit = 100;
            schoolLogList(data)
                .then((res) => {
                    if (res.status.code == 1) {
                        var list = res.data.List;
                        this.loginLogData = list;
                    } else {
                        this.$message.error(res.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        // 班级日志
        classLogList() {
            var data = {};
            data.page = 1;
            data.limit = 100;
            classLogList(data)
                .then((res) => {
                    if (res.status.code == 1) {
                        var list = res.data.List;
                        this.classLogData = list;
                    } else {
                        this.$message.error(res.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        // 学生日志
        stuLogList() {
            var data = {};
            data.page = 1;
            data.limit = 100;
            stuLogList(data)
                .then((res) => {
                    if (res.status.code == 1) {
                        var list = res.data.List;
                        this.stuLogData = list;
                    } else {
                        this.$message.error(res.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        // 视力日志
        healthLogList() {
            var data = {};
            data.page = 1;
            data.limit = 100;
            healthLogList(data)
                .then((res) => {
                    if (res.status.code == 1) {
                        var list = res.data.list;
                        this.healthLogData = list;
                    } else {
                        this.$message.error(res.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        // 选项卡
        handleClick(tab, event) {
            // console.log(tab, event);
        },
    },
};
</script>
<style lang="scss" scoped>
#body {
    margin: 30px;
}
</style>
