<template>
  <div id="body">
    <div class="fatherBox">
      <div class="stubody" style="margin:25px 40px 10px 10px;">
       <div style="float: right;padding: 10px;margin-top: -21px;">
              <el-button  type="primary" plain @click="upStuHealth()" >更新信息</el-button>
              <el-button  type="primary" @click="(dialogVisible = true)" >导出数据excel</el-button>
        </div>
        <div style="text-align: left;width:93%">
          <div style="margin-left:20px;">{{StuName}}眼睛健康信息</div>
          <template>
                <div style="width: 80%; margin: auto; margin-left: 80px;">
                  <el-form  label-width="120px" class="demo-ruleForm">
                    <el-form-item label="裸眼视力：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.Leye }}
                          <span v-if="stu_list.eyetime" style="font-size: 10px;">({{stu_list.eyetime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{ stu_list.Reye }}
                          <span v-if="stu_list.eyetime" style="font-size: 10px;">({{stu_list.eyetime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.eyetime" size="mini" @click="detail(1)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="球      镜(S)：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.zqj }}
                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{ stu_list.yqj }}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(2)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="柱      镜(C)：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.zzj }}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{ stu_list.yzj }}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(3)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="散光轴位(A)：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{stu_list.zzw}}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{stu_list.yzw}}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(4)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="水平子午线曲率MM1：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{stu_list.zmm1}}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{stu_list.ymm1}}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(5)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="垂直子午线曲率MM2：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{stu_list.zmm2}}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{stu_list.ymm2}}
                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(6)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="水平子午线角度A1：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{stu_list.za1}}
                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="右眼：">
                          {{stu_list.ya1}}
                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(7)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="瞳       距PD：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.tongj}}
                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(8)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="镜 眼 距VD：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.jyj}}

                          <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ygytime" size="mini" @click="detail(9)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="正相对调节：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.zxdtj}}
                          <span v-if="stu_list.zxdtjtime" style="font-size: 10px;">({{stu_list.zxdtjtime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.zxdtjtime" size="mini" @click="detail(10)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="负相对调节：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.fxdtj}}
                          <span v-if="stu_list.fxdtjtime" style="font-size: 10px;">({{stu_list.fxdtjtime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.fxdtjtime" size="mini" @click="detail(11)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="调节灵敏度：">
                      <el-col :span="8">
                        <el-form-item label="单眼：">
                          {{ stu_list.zlmd }}
                          <span v-if="stu_list.zlmdtime" style="font-size: 10px;">({{stu_list.zlmdtime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label=" 双眼：">
                          {{ stu_list.ylmd }}

                          <span v-if="stu_list.ylmdtime" style="font-size: 10px;">({{stu_list.ylmdtime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ylmdtime" size="mini" @click="detail(12)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="眼压：">
                      <el-col :span="8">
                        <el-form-item label="左眼：">
                          {{ stu_list.z_yany }}
                          <span v-if="stu_list.z_yanytime" style="font-size: 10px;">({{stu_list.z_yanytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label=" 右眼：">
                          {{ stu_list.y_yany }}

                          <span  v-if="stu_list.y_yanytime" style="font-size: 10px;">({{stu_list.y_yanytime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.y_yanytime"  size="mini" @click="detail(13)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="雾视眼光：">
                      <el-col :span="8">
                        <el-form-item label="雾视：">
                          {{ stu_list.zwsyg }}
                          <span v-if="stu_list.zwsygtime"  style="font-size: 10px;">({{stu_list.zwsygtime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label=" 不雾视：">
                          {{stu_list.ywsyg}}

                          <span v-if="stu_list.ywsygtime" style="font-size: 10px;">({{stu_list.ywsygtime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.ywsygtime" size="mini" @click="detail(14)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="BCC：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.bcc}}
                          <span v-if="stu_list.bcctime" style="font-size: 10px;">({{stu_list.bcctime}})</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.bcctime" size="mini" @click="detail(15)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="AMP调节幅度：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.apmtj}}
                          <span v-if="stu_list.apmtjtime" style="font-size: 10px;">({{stu_list.apmtjtime}})</span>

                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-button v-if="stu_list.apmtjtime" size="mini" @click="detail(16)">详情</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="立体视觉：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.ltsj}}
                          <span v-if="stu_list.ltsjtime" style="font-size: 10px;">({{stu_list.ltsjtime}})</span>

                        </el-form-item>
                      </el-col>

                    </el-form-item>
                    <el-form-item label="色盲：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.colorblind}}
                          <span v-if="stu_list.colorblindtime" style="font-size: 10px;">({{stu_list.colorblindtime}})</span>
                        </el-form-item>
                      </el-col>

                    </el-form-item>
                    <el-form-item label="色弱：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.colorweak}}
                          <span v-if="stu_list.colorweaktime" style="font-size: 10px;">({{stu_list.colorweaktime}})</span>
                        </el-form-item>
                      </el-col>

                    </el-form-item>
                    <el-form-item label="夜盲：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.nightblind}}
                          <span v-if="stu_list.nightblindtime" style="font-size: 10px;">({{stu_list.nightblindtime}})</span>

                        </el-form-item>
                      </el-col>

                    </el-form-item>
                    <el-form-item label="视野：">
                      <el-col :span="8">
                        <el-form-item>
                          {{stu_list.eyerange}}

                          <span v-if="stu_list.eyerangetime" style="font-size: 10px;">({{stu_list.eyerangetime}})</span>

                        </el-form-item>
                      </el-col>
                    </el-form-item>
                  </el-form>
                </div>
          </template>
       </div>
      </div>
      <el-dialog
      :visible.sync="dialogVisible"
      title='导出数据'
      width="500px"
    >
      <div>
        <el-form label-width="80px" class="demo-ruleForm">
        <el-form-item label="时间范围" >
          <el-date-picker
            v-model="daterange"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doExport">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import Vue from 'vue'
import {
  stuEyeSearch,
  excelDaochueyeDays
} from '@/api/SchoolApi.js'
import {
  teastuinfo
} from '@/api/TeacherApi.js'
export default {
  computed: {
    ...mapGetters(['Status'])
  },
  data () {
    return {
      stuUserID: '',
      StuName: '',
      dstatus: false,
      loading: false,
      cityid: '',
      stu_list: [],
      dialogVisible: false,
      daterange: []
    }
  },
  mounted () {
    this.stuUserID = this.$route.params.StuID // 给学生ID赋值 通过上个页面$route传递过来的
    this.StuName = this.$route.params.StuName
    this.health_detail()
    this.StuInfo()
  },
  methods: {
    // 视力详情
    health_detail () {
      this.loading = true
      const data = {
        studentid: this.stuUserID // 学生ID
      }
      stuEyeSearch(data).then(res => {
        this.loading = false
        this.stu_list = res.data.list
        this.city = res.data.city
      }).catch(err => {
        console.log(err)
      })
    },
    detail (type) { // 点击详情
      localStorage.setItem('Route', 'health') // 存跳转标识
      this.$router.push({
        name: 'shealth_history',
        params: {
          student_id: this.stuUserID,
          type: type,
          city: this.cityid,
          stuname: this.StuName
        }
      })
    },
    StuInfo () {
      const data = {
        stuUserID: this.stuUserID //
      }
      teastuinfo(data).then(res => {
        this.stuData = res.data	// 数据
        this.cityid = res.data.City	// 数据
      }).catch(err => {
        console.log(err)
      })
    },
    upStuHealth () { // 更新健康信息
      this.$router.push({
        name: 'health_update',
        params: {
          student_id: this.stuUserID
        }
      })
    },
    doExport () {
      this.loading = true
      if (this.daterange.length == 0) {
        this.$message.error('请选择时间范围')
        return false
      }
      const data = {
        studentid: this.stuUserID, // 学生ID
        city: this.cityid,
        startdate: this.daterange[0],
        enddate: this.daterange[1]
      }
      excelDaochueyeDays(data).then(res => {
        this.loading = false
        if (res.status.code == 1) {
          window.open(Vue.prototype.$url + res.data)
          this.dialogVisible = false
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  //新样式
    .el-dropdown-link {
      cursor: pointer;
      color: #409EFF;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
  .stutitle{
    font-size: 30px !important;
    margin-right: 55% !important;
  }

  .tt{
        height: 40px;
        line-height: 40px;
        font-size: 22px;
        font-weight: 500;
  }

  .tabtit{
      width: 100px;
      display: inline-block;
      text-align: right;
  }

  tr{
    line-height: 45px;
  }

  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
