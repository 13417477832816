<template>
	<div>
		<div class="fatherBox">
			<div class="headerBox">
				<div class="facility" v-if="identifying=='facility'?true:false">
					<p @click="$router.go(-1)" class="stulist">设备列表</p>
					<p>/</p>
					<p>编号：{{facilityID}}</p>
				</div>

				<div class="headerinfos" v-if="identifying=='School'?true:false">
					<p @click="$router.go(-2)" class="stulist">学校列表</p>
					<p>/</p>
					<p @click="$router.go(-1)">{{SchName}}设备列表</p>
					<p>/</p>
					<p>设备详情</p>
				</div>
			</div>
			<div class="headinfo">
				<div>
					<p>设备编号：{{facilityID}}</p>
					<p>设备地址：{{site}}</p>
				</div>
        <div>
        	<p>绑定类别：{{Sbtype}}</p>
        	<!-- <p>绑定时间：{{InsertTime.slice(0,16)}}</p> -->
        	<p>设备状态：{{Sbstatus}}</p>
        	<!-- <p>投放时间：{{LaunchTime.slice(0,16)}}</p> -->
        </div>
				<div>
					<p>绑定时间：{{InsertTime}}</p>
					<!-- <p>绑定时间：{{InsertTime.slice(0,16)}}</p> -->
					<p>投放时间：{{LaunchTime}}</p>
					<!-- <p>投放时间：{{LaunchTime.slice(0,16)}}</p> -->
				</div>
			</div>
		</div>
		<div class="ClassInfo_box">
			<div class="chartTitle">
				<!-- <p class="head_dates">日期：2020/09/09 19:52:25</p> -->
				<ul>
					<li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index)"
						:class="currentIndex == index ? 'active' : ''">
						{{ item }}
					</li>
				</ul>
			</div>
			<div id="main" style="width: 100%; height: 400px; background: white"></div>
		</div>
	</div>
</template>

<script>
	import {
		machineDetails,
    machineDetailsPage
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				Nums: '', // 传值设备ID
				type: '', //  传值设备类型
				facilityID: '', // 显示设备编号
				site: '', // 设备地址
				InsertTime: '', // 绑定时间
				LaunchTime: '', // 投放时间
				currentIndex: 0, // 索引
				dateList: ['今日', '七天', '30日'], // Table选项
				Series: [], // 折线图Y轴数据
				Dates: [], // 折线图X轴数据
				identifying: '', // 标识
				SchName: '' ,// 本地取值
        Sbtype: '' ,// 设备绑定类别
        Sbstatus: '' // 设备状态

			}
		},
		mounted() {
			this.Nums = this.$route.params.detailInfo; // 接收上页面传值设备ID facilitydetail/210/2 就是这个210
			this.type = this.$route.params.type; // 接收上页面传值设备类型 facilitydetail/210/2 就是这个2
      if(this.type ==1){
        this.Sbtype = '视力检测设备'
      }else if(this.type ==2){
        this.Sbtype = '音视频设备'
      }else if(this.type ==4){
        this.Sbtype = '校门安防设备'
      }
      this.site     = this.$route.params.schoolname == '' ? '暂无' : this.$route.params.schoolname // 设备地址
      this.Sbstatus = this.$route.params.sbstatus == 1 ? '运行中' : '离线' // 设备地址
      this.InsertTime = this.$route.params.InsertTime // 投放时间
      this.LaunchTime = this.$route.params.LaunchTime // 绑定时间
      this.facilityID = this.$route.params.MechineName // 设备编号
      
			this.identifying = localStorage.getItem('Route') // 对比本地的值进行显示隐藏面包屑
			this.SchName = localStorage.getItem('SchName') // 取出本地存放的学校名称
			this.machineDetails(1)
		},
		methods: {
			drawChart() {
				const that = this
				const myChart = this.$echarts.init(document.getElementById('main'))
				const options = {
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['检测数']
					},
					xAxis: {
						//   data: ['MOn', 'Tue', 'Ser', 'Fir', 'Sun', 'Sta']
						data: that.Dates
					},
					yAxis: {},
					series: [{
						name: '检测数',
						type: 'line',
						// data: [5, 20, 36, 10, 10, 20]
						data: that.Series
					}]
				}
				myChart.setOption(options)
			},
			machineDetails(nums) { // 设备详情
				const data = {
					mechineID: this.Nums, //	设备编号
					dataType: nums, //	数据类型 ( 1日 2周 3月 )
					mechineType: this.type //	设备类型
				}
				machineDetails(data).then(res => {
					// console.log(res);
					this.Dates = res.data.date // X轴数据
					this.Series = res.data.num // Y轴数据
					// this.serial = res.data.MechineName; //设备编号
					this.drawChart()
				}).catch(err => {
					console.log(err)
				})
			},
			Atpitch(index) { // 折线图时间选择索引
				this.currentIndex = index
				switch (index) {
					case 0:
						this.machineDetails(1)
						break
					case 1:
						this.machineDetails(2)
						break
					case 2:
						this.machineDetails(3)
						break
					default:
						this.machineDetails(1)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.fatherBox {
		width: 98%;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			margin-left: 25px;
			border-bottom: 2px solid #e9e9e9;

			.facility {
				display: flex;

				p {
					color: burlywood;

				}

				p:nth-child(3) {
					color: black;
				}
			}

			.headerinfos {
				display: flex;

				p {
					color: burlywood;

				}

				p:nth-child(5) {
					color: black;
				}

				p:nth-child(3) {
					cursor: pointer;
				}
			}

			p {
				font-size: 16px;
				font-family: 萍方-简;
				margin-left: 10px;
			}

			.stulist {
				color: #8C8C8C;
				cursor: pointer;
				// margin: 0 3px 0 32px;
			}
		}

		.headinfo {
			width: 745px;
			display: flex;
			margin-left: 100px;

			div {
				width: 373px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	.ClassInfo_box {
		width: 96%;
		margin: 0 auto;
		background: white;
		margin-top: 25px;

		.chartTitle {
			height: 51px;
			display: flex;
			align-items: center;

			// justify-content: space-between;
			//   border-bottom:1px solid #cccccc ;
			.head_dates {
				display: inherit;
				margin-left: 102px;
				font-weight: bold;
			}

			.facility {
				font-size: 16px;
				line-height: 24px;
				color: #1890FF;
				margin-left: 32px;
				font-family: PingFangSC-Medium;
			}

			ul {
				padding: 0;
				margin: 0;
				font-size: 14px;
				margin-left: 54px;

				li {
					float: left;
					list-style: none;
					padding-left: 33px;
				}

				li:hover {
					cursor: pointer
				}
			}
		}
	}

	.active {
		//检测人滑动点击css
		color: #1890FF;
		font-size: 14px;
	}
</style>
