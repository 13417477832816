<template>
    <div id="body">
        <el-breadcrumb separator-class="el-icon-arrow-right">
<!--            <el-breadcrumb-item :to="{ path: '/SchoolHome' }"
                >首页</el-breadcrumb-item
            > -->
            <el-breadcrumb-item :to="{ path: '/set_user' }"
                >设置</el-breadcrumb-item
            >
            <el-breadcrumb-item>账号密码</el-breadcrumb-item>
        </el-breadcrumb>
        <br /><br />
        <div class="header">
            <p class="info">修改密码需要向手机 {{ tel }} 发送验证码</p>
        </div>
        <el-button :disabled="isSend" @click="send_code()">{{
            send_code_text
        }}</el-button>
        <el-form>
            <br />
            <el-form-item label="请输入验证码">
                <el-input v-model="code" placeholder="请输入验证码"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
                <el-input
                    v-model="pass"
                    placeholder="新密码"
                    show-password
                ></el-input>
            </el-form-item>
            <el-form-item label="新密码确认">
                <el-input
                    v-model="notpass"
                    placeholder="新密码确认"
                    show-password
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { sendSms, passwdChange } from "@/api/setUser.js";
export default {
    data() {
        return {
            tel: "",
            rtel: "",
            isSend: false,
            send_code_text: "发送验证码",
            code: "",
            pass: "",
            notpass: "",
            messages : 0,
        };
    },
    mounted() {
        this.set_tel();
    },
    methods: {
        // 手机号
        set_tel() {
            var tel = this.$route.params.tel;
            if (tel) {
                localStorage.setItem("set_tel", tel);
                this.tel = tel;
            } else {
                if (localStorage.getItem("set_tel")) {
                    this.tel = localStorage.getItem("set_tel");
                }
            }
            var rtel = this.$route.params.rtel;
            if (rtel) {
                localStorage.setItem("set_rtel", rtel);
                this.rtel = rtel;
            } else {
                if (localStorage.getItem("set_rtel")) {
                    this.rtel = localStorage.getItem("set_rtel");
                }
            }
        },
        // 发送验证码
        send_code() {
            this.isSend = true;

            // 发送短信
            var data = {};
            data.phone = this.rtel;
            data.type = 3;
            this.sendSms(data);

        },
        // 发短信
        sendSms(data) {
            sendSms(data)
                .then((res) => {
                    const res_data =JSON.parse(res.data.data)
                    // 重新开始倒计时
                    if (res_data.status.code == 1) {
                      this.$message({
                        message: "发送成功",
                        type: "success",
                      });

                        // 60秒倒计时
                        var s = 60;
                        this.send_code_text = "已发送 " + s;
                        var _this = this;

                        var sendCode = setInterval(function () {
                            if (s < 1) {
                                window.clearInterval(sendCode);
                                _this.isSend = false;
                                _this.send_code_text = "发送验证码";
                            } else {
                                s = s - 1;
                                _this.send_code_text = "已发送 " + s;
                                console.log(_this.send_code_text);
                            }
                        }, 1000);

                    } else {
                      this.isSend =  false ;
                      this.$message.error(res_data.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        // 提交修改
        passwdChange(data) {
            passwdChange(data)
                .then((res) => {
                    if (res.status.code == 1) {
                        // 发送成功
                        this.$message({
                            message: "修改成功",
                            type: "success",
                        });
                        this.$router.push({
                            name: "set_user",
                        });
                    } else {
                        this.$message.error(res.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        submitForm(formName) {
            // 验证码
            this.code = this.code.replace(/(^\s*)|(\s*$)/g, "");
            if (!this.code) {
                this.$message.error("验证码不能为空");
                return;
            }
            // 密码
            this.pass = this.pass.replace(/(^\s*)|(\s*$)/g, "");
            if (!this.pass) {
                this.$message.error("新密码不能为空");
                return;
            }
            // 确认密码
            this.notpass = this.notpass.replace(/(^\s*)|(\s*$)/g, "");
            if (!this.notpass) {
                this.$message.error("确认密码不能为空");
                return;
            }
            // 二次密码是否一致
            if (this.pass != this.notpass) {
                this.$message.error("二次密码不一致");
                return;
            }
            // 提交修改
            var data = {};
            data.code = this.code;
            data.password = this.notpass;
            data.tel = this.rtel;
            this.passwdChange(data);
        },
    },
};
</script>
<style lang="scss" scoped>
#body {
    margin: 30px;
    width: 600px;
}
.header {
    .info {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        margin-left: 50px;
    }
}
</style>
