<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>设备列表</p>
        <el-button type="primary" class="btn" @click="dialogFormVisible = true">+ 绑定摄像头</el-button>
      </div>
     
      <el-table :data="cameraList" v-loading="loading">
        <el-table-column prop="ident" label="设备编号" align="center"></el-table-column>
        <el-table-column label="方向"   align="center">
			<template slot-scope="scope">
				{{scope.row.direction==1?'入校':'离校'}}
			</template>
		</el-table-column>
        <el-table-column label="位置" align="center" prop="position"></el-table-column>
        <el-table-column label="IP" align="center" prop="ip"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="250">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="removeCamera(scope.row)">删除摄像头</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="添加摄像头" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form :model="cameraInfo" ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入摄像头编码">
          <el-input v-model="cameraInfo.ident" style="width: 260px; float: left" placeholder="请输入设备编码" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入位置">
          <el-input v-model="cameraInfo.position" style="width: 260px; float: left" placeholder="请输入位置" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入IP地址">
          <el-input v-model="cameraInfo.ip" style="width: 260px; float: left" placeholder="请输入位置" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请选择方向">
           <el-radio v-model="cameraInfo.direction" label="1">入校</el-radio>
            <el-radio v-model="cameraInfo.direction" label="2">离校</el-radio>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleAdd">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <el-dialog title="删除摄像头" :visible.sync="removeDialog" top="30px">
      <span>确认是否删除摄像头?</span>
      <br />
      <br />
      <span>
        <p style="color:#1890FF;display:inline">设备编号：{{cameraInfo.ident}}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleRemove">确 定</el-button>
      </span>
    </el-dialog>

    

  </div>
</template>

<script>
  import {
    cameraList,addCamera,removeCamera
  } from '@/api/agentApi.js'
import { Message } from 'element-ui'
  export default {
	  props: ['machineId'],
    data() {
      return {
		  removeDialog: false,
		  loading: false,
       cameraList: [],
	   dialogFormVisible: false,
	   cameraInfo: {
		   ident: '',
		   position: '',
		   direction: '1',
		   ip: ''
	   }
      }
    },
    created() { //created：在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图

    },
    mounted() {
      this.getCameraList()
    },
    methods: {
			handleRemove() {
				removeCamera({capture_id: this.cameraInfo.id}).then(res=>{
					if (res.status.code==1) {
					this.$message.success('删除成功')
					this.removeDialog = false
					this.getCameraList()
					} else {
									  this.$message.error(res.status.msg)
									  }
				})
			},
		removeCamera(cameraInfo) {
			this.removeDialog = true
			this.cameraInfo = cameraInfo
		},
      getCameraList() {
		  cameraList({machine_id:this.machineId}).then(res=>{
			  this.cameraList = res.data
		  })
	  },
	  handleAdd() {
		  addCamera({...this.cameraInfo, machine_id: this.machineId}).then(res=>{
			  if (res.status.code==1) {
				  this.$message.success('添加成功')
				  this.dialogFormVisible = false
				  this.getCameraList()
			  } else {
				  this.$message.error(res.status.msg)
			  }
		  })
	  }
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
