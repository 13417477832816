<template>
	<div>
		<div class="fatherBox">
			<div class="headerBox headerinfos">
				<p @click="$router.go(-1)">学校收益</p>
				<p>/</p>
				<p>{{SchoolName}}每日收益</p>
			</div>
			<el-table :data="bonus_list" border style="width: 98%">
				<el-table-column prop="date" label="日期"></el-table-column>
				<el-table-column prop="SchoolName" label="学校名称"></el-table-column>
				<el-table-column prop="zsy" label="收益额"></el-table-column>
				<el-table-column prop="ts_bonus" label="广告收益"></el-table-column>
				<el-table-column prop="ts_read" label="广告推送量"></el-table-column>
				<el-table-column prop="yyp_bonus" label="音视频收益"></el-table-column>
				<el-table-column prop="yyp_num" label="音视频订购人次"></el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
					background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
				</el-pagination>
			</div>
		</div>


	</div>
</template>

<script>
	import {
		agentProfitSchool
	} from '@/api/agentApi.js'

	export default {
		data() {
			return {
				bonus_list: [], // 学生列表
				schoolid: '',
				SchoolName: '',
				datetype: 1,
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				isContinue: 0,
				dialogFormVisible: false, // 添加设备弹窗
			}
		},

		mounted() {
			this.schoolid = this.$route.params.schoolid; // 学校id赋值😜
			this.datetype = this.$route.params.datetype; // 天数id赋值😜
			this.SchoolName = this.$route.params.SchoolName; // 学校名称赋值😜
			this.bonusList(this.currpage); // 查询省市区
		},
		methods: {
			bonusList(currpage) { //查询所有学生
				const data = {
					limit: this.pagesize,
					page: this.currpage,
					schoolid: this.schoolid,
					datetype: this.datetype
				}
				agentProfitSchool(data).then(res => {
					// console.log('res.data.list', res.data.list)
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.bonus_list = res.data.list;
					this.isContinue = res.data.isContinue
				}).catch(err => {
					console.log(err)
				})
			},

			stuListSearchHandle(currpage) { // 带有查询时分页 当有条件查询 分页时走这个方法
				this.loading = true
				const data = {
					page: currpage, // 第一次查询时候默认1
					limit: this.pagesize, // 总数
					schoolid: this.schoolid,
					datetype: this.datetype
				}

				agentProfitSchool(data).then(res => {
					this.bonus_list = res.data.list // 已审核用户列表
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.loading = false
					//在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
					this.currpage = currpage;
					this.isContinue = res.data.isContinue
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				if (this.isContinue == 1) {
					this.currpage = val
					this.stuListSearchHandle(val)
				}
			},

		}
	}
</script>

<style lang="scss" scoped>
	#body {
		// height: 100%;
		// background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			// justify-content: space-between;
			// border-bottom: 2px solid #e9e9e9;

			p {
				font-size: 16px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #000000;
				// margin-left: 32px;
			}


		}

		.headerinfos {
			display: flex;

			p {
				color: burlywood;

			}

			p:nth-child(3) {
				cursor: pointer;
				color: black;
			}
		}

	}
</style>
