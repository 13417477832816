<template>
	<div>
		<div class="header">
			<p class="info">班级入离校信息</p>
			<div class="datalist">
				<div>
					<p style="color: #3aa0ff">{{arriveCount.authStuNum}}</p>
					<p>认证人数</p>
				</div>
				<div>
					<p style="color: #1ac574">{{arriveCount.normal}}</p>
					<p>正常人数</p>
				</div>
				<div>
					<p style="color: #ca8700">{{arriveCount.unnormalNum}}</p>
					<p>迟到早退</p>
				</div>
				<div>
					<p style="color: #ff0000">{{arriveCount.errorNum}}</p>
					<p>异常人数</p>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="info">
				<span>记录明细</span>
			</div>
			<div class="seach">
				<span>时间：</span>
				<el-date-picker class="time" size="medium" type="daterange" v-model="serchList.picker"
					start-placeholder="开始日期" range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions"
					value-format="yyyy-MM-dd" unlink-panels @change="pickerChange">
				</el-date-picker>
				<el-select class="select" v-model="serchList.classType" @change="classFilter" size="medium"
					placeholder="请选择年级" filterable clearable style="width: 120px;">
					<el-option v-for="item in gradeList" :key="item.ID" :label="item.TypeName" :value="item.ID">
					</el-option>
				</el-select>
				<el-select class="select" v-model="serchList.classId" @change="getData(1,serchList)" size="medium"
					placeholder="请选择班级" filterable clearable style="width: 120px;">
					<el-option v-for="item in chooseClassList" :key="item.ID" :label="item.ClassName" :value="item.ID">
					</el-option>
				</el-select>
			</div>
			<el-table :data="data" v-loading="loading">
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="classname" label="班级" align="center"></el-table-column>
				<el-table-column prop="date" label="日期" align="center"></el-table-column>
				<el-table-column prop="authStuNum" label="认证人数" align="center"></el-table-column>
				<el-table-column prop="arriveNum" label="实际到校人数" align="center"></el-table-column>
				<el-table-column prop="late" label="迟到人数" align="center"></el-table-column>
				<el-table-column prop="leave_early" label="早退人数" align="center"></el-table-column>
				<el-table-column label="查看详情" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="detail(scope.row)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total, prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px;text-align: center;">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		sclArriveCount,
		gradeList,
		classArriveList,
		chooseClassList
	} from '@/api/user.js'
	export default {
		data() {
			return {
				data: [], //班级出入数据
				serchList: {}, //搜索条件
				gradeList: [], //年级列表
				classList: [], //班级列表
				chooseClassList: [], //选择的年级对应的班级列表
				loading: false, //加载框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				arriveCount: {}, //头部数据
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近半个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			}
		},
		created() {
			this.getData(1, {}); // 班级出入列表
			this.getSclArriveCount(); // 获取头部数据
			this.getGradeList(); //获取年级列表
			this.getClassList(); //获取班级列表
		},
		methods: {
			async getSclArriveCount() { // 获取头部数据
				let res = await sclArriveCount(); //请求接口
				if(res.status.code == 1){
					this.arriveCount = res.data; //头部数据
				}else{
					this.$message.error(res.status.msg);
				}
			},
			async getGradeList() { // 获取年级列表
				let res = await gradeList(); //请求接口
				this.gradeList = res.data.list; //年级数据
			},
			async getClassList() { // 获取班级列表
				let res = await chooseClassList(); //请求接口
				this.classList = res.data.list; //班级数据
			},
			async getData(currpage, serchJson) { // 班级出入列表
				this.loading = true;
				serchJson.page = currpage; // 当前页数
				serchJson.limit = this.pagesize; // 每页条数
				serchJson.startDate = serchJson.picker && serchJson.picker.length > 0 ? serchJson.picker[0] :
					''; // 开始时间
				serchJson.endDate = serchJson.picker && serchJson.picker.length > 0 ? serchJson.picker[1] : ''; // 结束时间
				let res = await classArriveList(serchJson); //请求接口
				if(res.status.code == 1){
					this.CountNum = parseInt(res.data.CountNum); // 总条数
					this.data = res.data.List; // 班级出入列表数据
					this.currpage = currpage; //分页显示当前页
				}else{
					this.$message.error(res.status.msg);
				}
				this.loading = false;
			},
			classFilter() { //选择年级后过滤班级
				this.chooseClassList = this.classList.filter(item => item.ClassType == this.serchList.classType);
				this.getData(1, this.serchList);
			},
			pickerChange(e) { //选择日期
				this.getData(1, this.serchList); // 根据选取的日期获取班级出入列表数据
			},
			detail(row) { //跳去班级详情页
				this.$router.push({
					path: '/studentHistory',
					query: {
						classId: row.classID,
						date: row.date
					}
				})
			},
			handleCurrentChange(val) { // 分页
				this.getData(val, this.serchList);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		.info {
			text-align: left;
			font-size: 16px;
			font-weight: bold;
			margin-left: 50px;
		}

		.datalist {
			display: flex;
			justify-content: flex-start;
			margin-left: 10%;

			div {
				width: 200px;

				p:nth-child(1) {
					font-size: 20px;
					font-weight: normal;
				}

				p:nth-child(2) {
					font-size: 14px;
					font-weight: normal;
					color: #000000;
				}
			}
		}
	}

	.table {
		padding: 20px 50px;
		margin-top: 20px;

		.info {
			text-align: left;
			font-size: 16px;
			font-weight: bold;
			line-height: 50px;
		}

		.seach {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 550px;

			.time {
				width: 250px;
			}
		}

	}
</style>
