<script>
import { mapInit, fetchPosOptions, fetchCityLngLat } from '../views/utils/amap'

export default {
    props: {
        address: {  // 地址
            type: String,
            default: ''
        },
        pos: {  // 坐标
          type: Array,
          default: []
        }
    },
    data() {
        return {
            inputValue: '', //输入框文字
            map: null, //地图对象
            posOptions: [], //推荐地址选项
            posData: {}, //位置数据
            loginInfo: JSON.parse(localStorage.getItem('userInfo')),
            amap: null
        }
    },
    async mounted() {

        // 默认显示参数传来的地址
        this.inputValue = this.address

        // 参数传坐标，显示参数位置 否则显示城市中心
        let pos = []
        if (this.pos.length > 0) {
          pos = this.pos
        } else { //按城市名获取默认坐标
          pos = await fetchCityLngLat(this.loginInfo.cname)
        }

        //初始化地图
        this.amap = await mapInit()
        this.map = new this.amap.Map('mapContainer', {
            viewMode: '3D',
            rotation: -15,
            rotateEnable: true,
            pitchEnable: true,
            pitch: 50,
            zoom: 17,
            center: pos
        });
    },
    methods: {
        //自动推荐地址选项
        async fetchPosOptions() {
            //更改地址将失去坐标
            this.$emit('update', {
                wei_du: '',
                jing_du: '',
                addr: ''
            })
            this.posOptions = []
            if (this.inputValue) {
                let options = await fetchPosOptions(this.loginInfo.cname, this.inputValue)
                this.posOptions = options || []  //防止未返回正确列表数据
                this.posOptions = options.filter(item => item.location)  //过滤出有坐标的数据，排除没有坐标的数据
            }
        },

        //选中地址
        async selectPosition(item) {

            this.posOptions = []
            this.inputValue = item.district + item.address
            this.posData = { ...item, lnglat: item.location.lng + ',' + item.location.lat }
            this.$emit('update', {//告诉父组件 并且把值传过去
                wei_du: item.location.lat,
                jing_du: item.location.lng,
                addr: item.district + item.address,
            })
            //地图跳转到坐标位置
            this.map.setCenter(new this.amap.LngLat(item.location.lng, item.location.lat))
            //添加一个图钉
            const marker = new this.amap.Marker({
                position: new this.amap.LngLat(item.location.lng, item.location.lat),
                title: item.name
            })
            this.map.add(marker)
        }
    }
}
</script>
<template>
    <div>
        <el-input v-model="inputValue" @input="fetchPosOptions" placeholder="输入地址" />
        <ul id="optionsList" class="map-options-list" v-if="posOptions.length > 0">
            <li v-for="item in posOptions">
                <el-link @click="selectPosition(item)">{{ item.name }}({{ item.address }})</el-link>
            </li>
        </ul>
        <!-- 坐标：{{ posData.lnglat || '匹配地址后显示' }} -->
        <div id="mapContainer" style="margin-top:10px;width:100%;height:200px;"></div>
    </div>
</template>

<style>
.map-options-list {
    position: absolute;
    background: white;
    z-index: 999;
    margin: 0;
    list-style: none;
    padding: 0 15px;
}

.map-options-list li {
    line-height: 30px;
    margin: 0;
    padding: 0;
}
</style>
