<template>
	<div class="side-menu">
		<!-- <div class="menu-item" @click="navTo('adverPlanAccount')" v-if="$router.currentRoute.name=='adverPlanAccount'">账户信息</div> -->
		<div class="menu-item" @click="navTo('certification')" v-if="routerName=='certification'">资质管理</div>
		<div class="menu-item" @click="navTo('adverPlanAccount')" v-if="routerName!='certification'">账户记录</div>
		<div class="menu-item" @click="navTo('adPassword')" v-if="routerName!='certification'">密码修改</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				routerName: ''
			}
		},
		mounted() {
			this.onUpdate()
		},
		watch: {
			$route() {
				this.onUpdate()
			}
		},
		methods: {
			onUpdate() {
				this.routerName = this.$router.currentRoute.name
			},
			navTo(name) {
				this.$router.push({name})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.side-menu {
		background: #FDF9F9;
		min-height: calc(100vh - 150px);

		.menu-item {
			text-align: left;
			padding: 20px;
			border-bottom: 2px solid #f0f2f5;
			cursor: pointer;
		}
	}
</style>