<template>
	<div>
		<div class="header">
			<span v-if="!!userInfo.stuId && Status == 'school'">
				<span class="back" @click="$router.go(-2)">班级入离校信息</span>
				/
				<span class="back" @click="$router.go(-1)">学生入离校信息</span>
				/ {{userInfo.stuName}}的入离校信息
			</span>
			<span v-else-if="!!userInfo.teacherId && Status == 'school'">
				<span class="back" @click="$router.go(-2)">记录明细</span>
				/
				<span class="back" @click="$router.go(-1)">教职工出入信息</span>
				/ {{userInfo.teacherName}}的出入信息
			</span>
			<span v-else>
				<span class="back" @click="$router.go(-1)">学生入离校信息</span>
				/ {{userInfo.stuName}}的入离校信息
			</span>
		</div>
		<div class="content" v-if="!!userInfo.stuId">
			<div class="main" v-for="(item,index) in data" :key="index">
				<div class="head-info">
					<span>{{item.time}} {{explain(item)}}</span>
					<span>检测地点：{{item.cname}}</span>
				</div>
				<div class="img-div" v-for="pic in item.stuPic">
					<img :src="pic" alt="照片" width="640px" height="360px">
				</div>
			</div>
		</div>
		<div class="content" v-else>
			<div class="main" v-for="(item,index) in data" :key="index">
				<div class="head-info">
					<span>{{item.createtimereal}} {{item.desc}}</span>
					<span>检测地点：{{item.position}}</span>
				</div>
				<div class="img-div">
					<img :src="item.photoparent" alt="照片" width="640px" height="360px">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		arriveInfoByUserid
	} from '@/api/user.js'
	import {
		stuArriveInfoByDate
	} from '@/api/TeacherApi.js'
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				data: [], //详细数据
				loading: false, //加载框
				userInfo: JSON.parse(this.$route.query.userInfo), //学生信息或者教职工信息
			}
		},
		mounted() {
			if (!!this.userInfo.stuId) {
				this.getStuData(); // 学生出入详细信息
			} else {
				this.getData(); // 教职工出入详细信息
			}
		},
		computed: {
			...mapGetters(["Status"]),
			explain() {
				return function(item) {
					let str = '';
					switch (item.explain) {
						case 1:
							str = item.gua + '到校';
							break;
						case 2:
							str = '学生正常到校';
							break;
						case 3:
							str = '学生异常到校';
							break;
						case 4:
							str = '学生正常离校';
							break;
						case 5:
							str = '学生异常离校';
							break;
					};
					return str;
				}
			}
		},
		methods: {
			async getData() { // 教职工出入信息
				this.loading = true;
				const data = {
					userId: this.userInfo.teacherId, //教职工id
					date: this.userInfo.teacherDate // 查看的日期
				}
				let res = await arriveInfoByUserid(data); //请求接口
				this.data = res.data; // 教职工出入信息数据
				this.loading = false;
			},
			async getStuData() { // 学生出入信息
				this.loading = true;
				const data = {
					stuId: this.userInfo.stuId, //学生id
					date: this.userInfo.stuDate // 查看的日期
				}
				let res = await stuArriveInfoByDate(data); //请求接口
				this.data = res.data.list; // 学生出入信息数据
				this.loading = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 20px;
		font-weight: bold;
		line-height: 50px;
		margin-left: 50px;

		.back {
			color: #79740E;
			cursor: pointer;
		}
	}

	.content {
		.main {
			width: 800px;
			margin: 20px auto;

			.head-info {
				width: 400px;
				margin: auto;
				line-height: 60px;

				span {
					display: inline-block;
					width: 180px;
				}

				span:nth-child(1) {
					text-align: left;
					margin-right: 40px;
				}

				span:nth-child(2) {
					text-align: left;
				}
			}

			// .img-div {
			// 	padding: 50px;
			// 	margin: 0 auto;
			// 	box-shadow: 0 0 5px 5px #c8c9cc;
			// }
		}
	}
</style>
