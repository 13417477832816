<template>
<div>
  <div class="query">
      <div class="queryLeft">
          <div style="float: left ;width: fit-content;margin-right: 10px;" v-if=" Status == 'school' ? true :false">
              学生查询:
              <el-input v-model="studentName" placeholder="请输入学生姓名"></el-input>
          </div>
					<div style="float: left;width: fit-content;margin-right: 10px;"
						v-if=" Status == 'school' ? true :Status == 'teacher' ? true : false">
						学号:
						<el-input class="select" v-model.trim="stu_id" placeholder="清输入学生学号" style="width: 160px;">
						</el-input>
					</div>&nbsp;&nbsp;
          <div>
            <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="ClassStudentsList(1)">查询</el-button>
          </div>
      </div>
      <div class="queryRight" style="display: flex;">
          <div class="dataBox" @click="classEchart()">
              <div class="num">{{classlhv}}</div>
              <div class="text">班级近视率</div>
          </div>
      </div>
  </div>
    <el-table :data="ClassStudentsInfos" v-loading="loading">
        <el-table-column label="学生姓名" prop="StuName"  align="center"></el-table-column>
				<el-table-column label="学号" prop="stu_id"  align="center"></el-table-column>
        <!-- <el-table-column label="长编号" prop="StuNumber"  align="center"></el-table-column> -->
        <!-- <el-table-column label="短编号" prop="sid"  align="center"></el-table-column> -->
        <el-table-column label="裸眼视力(左)" prop="hytest"  align="center">
            <template slot-scope="scope">
                <span >{{scope.row.LEye?scope.row.LEye:'暂无'}}</span>
                <span v-if="scope.row.lbgnum" style="margin-left:5px;">
                    <span v-if="scope.row.lbgc==1" style="color:green;"> {{scope.row.lbgnum}}</span>
                    <span v-if="scope.row.lbgc==2" style="color:red;"> {{scope.row.lbgnum}}</span>
                </span>
          </template>
        </el-table-column>
        <el-table-column label="裸眼视力(右)" prop="classlhv"  align="center">
            <template slot-scope="scope">
            <span >{{scope.row.REye?scope.row.REye:'暂无'}}</span>
            <span v-if="scope.row.rbgnum" style="margin-left:5px;">
                <span v-if="scope.row.lbgc==1" style="color:green;"> {{scope.row.rbgnum}}</span>
                <span v-if="scope.row.lbgc==2" style="color:red;"> {{scope.row.rbgnum}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="班级排名" prop="crank"  align="center"></el-table-column>
        <el-table-column label="年级排名" prop="grank"  align="center"></el-table-column>
        <el-table-column label="全区排名" prop="arank"  align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="doDetail(scope.$index, scope.row)">查看详情
            </el-button>
          </template>
        </el-table-column>
    </el-table>
    <div v-show="CountNum > 0">
    <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
    </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title='班级历史近视率'
    >
    <div id="main" style="width: 98%; height: 350px; background: white"></div>
    </el-dialog>
</div>
</template>
<script>
import {
  classStudentsDataList,
  classGoodPic
} from '@/api/SchoolApi.js'
import {
  mapGetters
} from 'vuex'
export default {
  computed: {
    ...mapGetters(['Status'])
  },
  data () {
    return {
		stu_id: '', // 查询学号
      ClassStudentsInfos: [],
      loading: false, // 加载框
      pagesize: 12, // 每页显示条目个数
      currpage: 1, // 初始页
      CountNum: 0, // 总条数
      gradeId: '',
      classId: '',
      studentName: '',
      classlhv: '',
      Series: [], // 折线图Y轴数据
      Dates: [], // 折线图X轴数据
      dialogVisible: false
    }
  },
  mounted () {
    this.gradeId = this.$route.params.gradeId
    this.classId = this.$route.params.classId
    this.ClassStudentsList(1) // 获取学生列表
  },
  methods: {
    reset () { // 重置按钮
      this.ClassStudentsInfos = []
      this.studentName = ''
      this.currpage = 1
      this.ClassStudentsList(this.currpage)
    },
    ClassStudentsList (currpage) {
      this.loading = true
      const data = {
        page: currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        classId: this.classId,
        gradeId: this.gradeId,
        search: this.studentName,
		stu_id: this.stu_id
      }
      classStudentsDataList(data).then(res => {
        this.ClassStudentsInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum) // 总条数
        this.classlhv = res.data.jsl
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleCurrentChange (val) {
      this.ClassStudentsList(val)
      this.currpage = val
    },
    drawChart () {
      const that = this
      const myChart = this.$echarts.init(document.getElementById('main'))
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['近视率']
        },
        xAxis: {
          data: that.Dates
        },
        yAxis: {},
        series: [{
          name: '近视率',
          type: 'line',
          data: that.Series
        }]
      }
      myChart.setOption(options)
    },
    classEchart () { // 全校近视率-折线图
      this.dialogVisible = true
      const param = {
        ClassID: this.classId
      }
      classGoodPic(param).then(res => {
        // console.log(res)
        this.Series = res.data.jnum
        this.Dates = res.data.date
        this.drawChart()
      }).catch(err => {
        console.log(err)
      })
    },
    // 查看详情
    doDetail (index, row) {
      this.$router.push({ // 页面跳转
        name: 'classStudentDetail', // 往这个页面跳转
        params: { // 跳转传递的参数
          StuID: row.sid,
          StuName: row.StuName
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  .dataBox{
    padding:5px 20px;
    font-size:18px;
    cursor:pointer;
    .num{
      color:#409EFF
    }
  }
</style>
