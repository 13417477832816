<template>
  <div>
    <div class="fatherBox">
      <div class="headerBox">
        <div class="facility" v-if="identifying=='facility'?true:false">
          <p @click="$router.go(-1)" class="stulist">设备列表</p>
          <p>/</p>
          <p>编号：{{facilityID}}</p>
        </div>

        <div class="headerinfos" v-if="identifying=='School'?true:false">
          <p @click="$router.go(-2)" class="stulist">学校列表</p>
          <p>/</p>
          <p @click="$router.go(-1)">{{SchName}}设备列表</p>
          <p>/</p>
          <p>设备详情</p>
        </div>
      </div>
      <div class="headinfo">
        <div>
          <p>设备编号：{{facilityID}}</p>
          <p>设备地址：{{site}}</p>
        </div>
        <div>
          <p>绑定类别：{{Sbtype}}</p>
          <!-- <p>绑定时间：{{InsertTime.slice(0,16)}}</p> -->
          <p>设备状态：{{Sbstatus}}</p>
          <!-- <p>投放时间：{{LaunchTime.slice(0,16)}}</p> -->
        </div>
        <div>
          <p>绑定时间：{{InsertTime}}</p>
          <!-- <p>绑定时间：{{InsertTime.slice(0,16)}}</p> -->
          <p>投放时间：{{LaunchTime}}</p>
          <!-- <p>投放时间：{{LaunchTime.slice(0,16)}}</p> -->
        </div>
      </div>
    </div>
    <el-table :data="machinetestlist" stripe style="width: 100%" v-loading="loading" ref="multipleTable"
        tooltip-effect="dark">
        <!-- Array ( [0] => Array ( [date] => 2022-01-08 [tsum] => 3 [jinsum] => 2 [jinshiPer] => 66.67% ) ) -->
        <el-table-column prop="date" label="日期" align="center">
        </el-table-column>
        <el-table-column prop="tsum" label="检测数量" align="center">
        </el-table-column>
        <el-table-column prop="jinshiPer" label="近视率" align="center">
        </el-table-column>
      </el-table>
    <div class="block">
    	<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
    		layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
    		style="margin-top:20px">
    	</el-pagination>
    </div>
    <div class="ClassInfo_box">
      <div class="chartTitle">
        <!-- <p class="head_dates">日期：2020/09/09 19:52:25</p> -->
        <ul>
          <li style="font-size: larger;" v-for="(item, index) in dateList" :key="index" @click="Atpitch(index)"
            :class="currentIndex == index ? 'active' : ''">
            {{ item }}
          </li>
          <li>
            <el-date-picker v-model="value2" type="daterange" size="small" :picker-options="pickerOptions"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
              value-format="timestamp">
            </el-date-picker>
            &nbsp;
            <el-button @click="Atpitch(4)" type="primary">查询</el-button>
          </li>
        </ul>
      </div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="检测次数" name="first">
          <div id="main" style="width: 100%; height: 400px; background: white"></div>
        </el-tab-pane>
        <el-tab-pane label="近视率" name="second">
          <div id="mainjin" style="width: 1200%; height: 400px; background: white"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import {
    machineDetails,
    machineDetailsPage
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        activeName: 'first',
        currpage: 1, // 初始页
        pagesize: 10, // 每页显示条目个数
        CountNum: 0, // 总条数
        machinetestlist:[],
        loading: false, //加载框
        Nums: '', // 传值设备ID
        type: '', //  传值设备类型
        facilityID: '', // 显示设备编号
        site: '', // 设备地址
        InsertTime: '', // 绑定时间
        LaunchTime: '', // 投放时间
        currentIndex: 0, // 索引
        dateList: ['今日', '七天', '30日'], // Table选项
        Series: [], // 折线图Y轴数据 数量
        Seriesjin: [], // 折线图Y轴数据 近视率
        Dates: [], // 折线图X轴数据
        identifying: '', // 标识
        SchName: '', // 本地取值
        Sbtype: '', // 设备绑定类别
        Sbstatus: '', // 设备状态
        value2: '',
        pickerOptions: {
          // 设置不能选择的日期
          onPick: ({
            maxDate,
            minDate
          }) => {
            this.choiceDate0 = minDate.getTime();
            if (maxDate) {
              this.choiceDate0 = '';
            }
          },
          disabledDate: (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime();
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1) + 86400000 * 2;
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            const max = newDate < maxTime ? newDate : maxTime;
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            if (this.choiceDate0) {
              return time.getTime() < min || time.getTime() > max;
            }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate;
          }
        },
      }
    },
    mounted() {
      this.Nums = this.$route.params.detailInfo; // 接收上页面传值设备ID facilitydetail/210/2 就是这个210
      this.type = this.$route.params.type; // 接收上页面传值设备类型 facilitydetail/210/2 就是这个2
      if (this.type == 1) {
        this.Sbtype = '视力检测设备'
      } else if (this.type == 2) {
        this.Sbtype = '音视频设备'
      } else if (this.type == 4) {
        this.Sbtype = '校门安防设备'
      }
      this.site = this.$route.params.schoolname == '' ? '暂无' : this.$route.params.schoolname // 设备地址
      this.Sbstatus = this.$route.params.sbstatus == 1 ? '运行中' : this.$route.params.sbstatus == 2 ? '离线':'' // 设备状态
      this.InsertTime = this.$route.params.InsertTime // 投放时间
      this.LaunchTime = this.$route.params.LaunchTime // 绑定时间
      this.facilityID = this.$route.params.MechineName // 设备编号

      this.identifying = localStorage.getItem('Route') // 对比本地的值进行显示隐藏面包屑
      this.SchName = localStorage.getItem('SchName') // 取出本地存放的学校名称
      this.machineDetails(1)
      this.machineDetailsPage(1)
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      },
      drawChart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('main'))
        const options = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['检测数'],
          },
          xAxis: {
            //   data: ['MOn', 'Tue', 'Ser', 'Fir', 'Sun', 'Sta']
            data: that.Dates
          },
          yAxis: {},
          series: [{
            name: '检测数',
            type: 'line',
            // data: [5, 20, 36, 10, 10, 20]
            data: that.Series
          }, ]
        }
        myChart.setOption(options)
      },
      drawChartjin() {
        const thatjin = this
        const myChartjin = this.$echarts.init(document.getElementById('mainjin'))
        const optionsjin = {
          tooltip: {
            trigger: 'axis',
            formatter: function(datas) //将y轴data数据转为百分比显示或者其他
            {
              var res = datas[0].name + '<br/>',
                val;
              for (var i = 0, length = datas.length; i < length; i++) {
                val = (datas[i].value) + '%';
                res += datas[i].seriesName + '：' + val + '<br/>';
              }
              return res;
            }
          },
          legend: {
            data: ['近视率'],
          },
          xAxis: {
            //   data: ['MOn', 'Tue', 'Ser', 'Fir', 'Sun', 'Sta']
            data: thatjin.Dates
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %', //将y轴百分比显示
            },
            show: true
          },

          series: [{
            name: '近视率',
            type: 'line',
            // data: [5, 20, 36, 10, 10, 20]
            data: thatjin.Seriesjin
          }]
        }
        myChartjin.setOption(optionsjin)
      },
      machineDetails(nums) { // 设备详情折线图
        var data = {};
        if (nums == 4 && this.value2 == null || nums == 4 && this.value2 == "") {
          return false;
        }

        if (nums == 4) {
          data = {
            mechineID: this.Nums, //	设备编号
            dataType: nums, //	数据类型 ( 1日 2周 3月 )
            mechineType: this.type, //	设备类型
            startDate: this.value2[0] / 1000, //	设备类型
            endDate: this.value2[1] / 1000 + 86399, //	设备类型
          }
        } else {
          data = {
            mechineID: this.Nums, //	设备编号
            dataType: nums, //	数据类型 ( 1日 2周 3月 )
            mechineType: this.type //	设备类型
          }
        }

        machineDetails(data).then(res => {
          // console.log(res);
          this.Dates = res.data.date // X轴数据
          this.Series = res.data.num // Y轴数据
          this.Seriesjin = res.data.numjin // Y轴数据

          // this.serial = res.data.MechineName; //设备编号
          this.drawChart()
          this.drawChartjin()
        }).catch(err => {
          console.log(err)
        })
      },
      machineDetailsPage(currpage) { // 设备详情分页
      this.loading = true
        var data = {
          mechineID: this.Nums, //	设备编号
          mechineType: this.type,
          page: currpage,
          limit: this.pagesize,
          }

        machineDetailsPage(data).then(res => {
          this.loading = false;
          this.machinetestlist = res.data.List //
          this.CountNum = parseInt(res.data.CountNum) // 总条数
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.currpage = val
        this.machineDetailsPage(val)
      },
      Atpitch(index) { // 折线图时间选择索引
        this.currentIndex = index
        switch (index) {
          case 0:
            this.value2 = '';
            this.machineDetails(1)
            break
          case 1:
            this.value2 = '';
            this.machineDetails(2)
            break
          case 2:
            this.value2 = '';
            this.machineDetails(3)
            break
          case 4:
            this.machineDetails(4)
            break
          default:
            this.machineDetails(1)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fatherBox {
    width: 98%;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      margin-left: 25px;
      border-bottom: 2px solid #e9e9e9;

      .facility {
        display: flex;

        p {
          color: burlywood;

        }

        p:nth-child(3) {
          color: black;
        }
      }

      .headerinfos {
        display: flex;

        p {
          color: burlywood;

        }

        p:nth-child(5) {
          color: black;
        }

        p:nth-child(3) {
          cursor: pointer;
        }
      }

      p {
        font-size: 16px;
        font-family: 萍方-简;
        margin-left: 10px;
      }

      .stulist {
        color: #8C8C8C;
        cursor: pointer;
        // margin: 0 3px 0 32px;
      }
    }

    .headinfo {
      width: 745px;
      display: flex;
      margin-left: 100px;

      div {
        width: 373px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      //   border-bottom:1px solid #cccccc ;
      .head_dates {
        display: inherit;
        margin-left: 102px;
        font-weight: bold;
      }

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-left: 54px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    color: #1890FF;
    font-size: 14px;
  }
</style>
