<template>
  <div>
    <div style="width: 80%; margin: auto; margin-left: 80px;">
      <div class="headerBox headerinfos">
        <p @click="$router.go(-1)">健康列表</p>
        <p>/</p>
        <p>视力详情</p>
        <div>
          <el-form label-width="120px" class="demo-ruleForm" style="margin-left: 400px;">
            <el-form-item>
              <el-button round @click="detail_up()">修改信息</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" round @click="view_report()">查看报告单</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div style="width: 80%; margin: auto; margin-left: 80px;">
      <el-form :model="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="裸眼视力：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{ stu_list.Leye }}
              <span v-if="stu_list.eyetime" style="font-size: 10px;">({{stu_list.eyetime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{ stu_list.Reye }}
              <span v-if="stu_list.eyetime" style="font-size: 10px;">({{stu_list.eyetime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.eyetime" size="mini" @click="detail(1)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="球      镜(S)：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{ stu_list.zqj }}
              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{ stu_list.yqj }}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(2)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="柱      镜(C)：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{ stu_list.zzj }}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{ stu_list.yzj }}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(3)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="散光轴位(A)：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{stu_list.zzw}}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{stu_list.yzw}}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(4)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="水平子午线曲率MM1：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{stu_list.zmm1}}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{stu_list.ymm1}}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(5)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="垂直子午线曲率MM2：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{stu_list.zmm2}}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{stu_list.ymm2}}
              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(6)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="水平子午线角度A1：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{stu_list.za1}}
              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="右眼：">
              {{stu_list.ya1}}
              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(7)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="瞳       距PD：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.tongj}}
              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(8)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="镜 眼 距VD：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.jyj}}

              <span v-if="stu_list.ygytime" style="font-size: 10px;">({{stu_list.ygytime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ygytime" size="mini" @click="detail(9)">详情</el-button>
          </el-col>
        </el-form-item>

        <el-form-item label="worth4融像：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.worth_image}}
              <span v-if="stu_list.worth_imagetime" style="font-size: 10px;">({{stu_list.worth_imagetime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.worth_imagetime" size="mini" @click="detail(20)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="远融像：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.far_image}}
              <span v-if="stu_list.far_imagetime" style="font-size: 10px;">({{stu_list.far_imagetime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.far_imagetime" size="mini" @click="detail(22)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="近融像：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.near_image}}
              <span v-if="stu_list.near_imagetime" style="font-size: 10px;">({{stu_list.near_imagetime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.near_imagetime" size="mini" @click="detail(23)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="远用眼位：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.yyyw}}
              <span v-if="stu_list.yyywtime" style="font-size: 10px;">({{stu_list.yyywtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.yyywtime" size="mini" @click="detail(19)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="近用眼位：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.jyyw}}
              <span v-if="stu_list.jyywtime" style="font-size: 10px;">({{stu_list.jyywtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.jyywtime" size="mini" @click="detail(18)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="集合近点（NPC）：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.jhjd}}
              <span v-if="stu_list.jhjdtime" style="font-size: 10px;">({{stu_list.jhjdtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.jhjdtime" size="mini" @click="detail(17)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="AC/A：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.ac_a}}
              <span v-if="stu_list.ac_atime" style="font-size: 10px;">({{stu_list.ac_atime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ac_atime" size="mini" @click="detail(21)">详情</el-button>
          </el-col>
        </el-form-item>

        <el-form-item label="正相对调节：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.zxdtj}}
              <span v-if="stu_list.zxdtjtime" style="font-size: 10px;">({{stu_list.zxdtjtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.zxdtjtime" size="mini" @click="detail(10)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="负相对调节：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.fxdtj}}
              <span v-if="stu_list.fxdtjtime" style="font-size: 10px;">({{stu_list.fxdtjtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.fxdtjtime" size="mini" @click="detail(11)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="调节灵敏度：">
          <el-col :span="8">
            <el-form-item label="单眼：">
              {{ stu_list.zlmd }}
              <span v-if="stu_list.zlmdtime" style="font-size: 10px;">({{stu_list.zlmdtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label=" 双眼：">
              {{ stu_list.ylmd }}

              <span v-if="stu_list.ylmdtime" style="font-size: 10px;">({{stu_list.ylmdtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ylmdtime" size="mini" @click="detail(12)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="眼压：">
          <el-col :span="8">
            <el-form-item label="左眼：">
              {{ stu_list.z_yany }}
              <span v-if="stu_list.z_yanytime" style="font-size: 10px;">({{stu_list.z_yanytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label=" 右眼：">
              {{ stu_list.y_yany }}

              <span v-if="stu_list.y_yanytime" style="font-size: 10px;">({{stu_list.y_yanytime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.y_yanytime" size="mini" @click="detail(13)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="雾视眼光：">
          <el-col :span="8">
            <el-form-item label="雾视：">
              {{ stu_list.zwsyg }}
              <span v-if="stu_list.zwsygtime" style="font-size: 10px;">({{stu_list.zwsygtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label=" 不雾视：">
              {{stu_list.ywsyg}}

              <span v-if="stu_list.ywsygtime" style="font-size: 10px;">({{stu_list.ywsygtime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.ywsygtime" size="mini" @click="detail(14)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="BCC：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.bcc}}
              <span v-if="stu_list.bcctime" style="font-size: 10px;">({{stu_list.bcctime}})</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.bcctime" size="mini" @click="detail(15)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="AMP调节幅度：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.apmtj}}
              <span v-if="stu_list.apmtjtime" style="font-size: 10px;">({{stu_list.apmtjtime}})</span>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="stu_list.apmtjtime" size="mini" @click="detail(16)">详情</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="立体视觉：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.ltsj}}
              <span v-if="stu_list.ltsjtime" style="font-size: 10px;">({{stu_list.ltsjtime}})</span>

            </el-form-item>
          </el-col>

        </el-form-item>
        <el-form-item label="色盲：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.colorblind}}
              <span v-if="stu_list.colorblindtime" style="font-size: 10px;">({{stu_list.colorblindtime}})</span>
              {{stu_list.colorblindstatus}}
            </el-form-item>
          </el-col>

        </el-form-item>
        <el-form-item label="色弱：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.colorweak}}
              <span v-if="stu_list.colorweaktime" style="font-size: 10px;">({{stu_list.colorweaktime}})</span>
            </el-form-item>
          </el-col>

        </el-form-item>
        <el-form-item label="夜盲：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.nightblind}}
              <span v-if="stu_list.nightblindtime" style="font-size: 10px;">({{stu_list.nightblindtime}})</span>

            </el-form-item>
          </el-col>

        </el-form-item>
        <el-form-item label="视野：">
          <el-col :span="8">
            <el-form-item>
              {{stu_list.eyerange}}

              <span v-if="stu_list.eyerangetime" style="font-size: 10px;">({{stu_list.eyerangetime}})</span>

            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>
<script>
  import {
    healthStuEyeSearch
  } from '@/api/agentApi.js'
  import {
    excel_eye_word
  } from '@/api/agent_newApi.js'

  export default {
    data() {
      return {
        ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
        student_id: '', //学生id
        loading: false, //加载框
        stu_list: [],
        city: '', //城市
      };
    },

    mounted() {
      this.student_id = this.$route.params.student_id; // 学生id赋值😜
      this.health_detail();
    },
    methods: {
      //查看报告单
      view_report() {
        const data = {
          studentid: this.student_id,
          city: this.city
        }
        excel_eye_word(data).then(res => {

          if (res.status.code == 1) {
            const url = this.$url + res.data;
            // window.open(url, '_self');
            //window.open('http://sygp.shiyuntech.net' + res.data)
            window.open(this.$url+res.data)
          } else {
            this.$message.error(res.status.msg);
          }

        }).catch(err => {
          console.log(err)
        })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      detail(type) { // 点击详情

        localStorage.setItem('Route', 'health') // 存跳转标识
        this.$router.push({
          name: 'health_history',
          params: {
            student_id: this.student_id,
            type: type,
            city: this.city,
          }
        })
      },

      //视力详情
      health_detail() {
        this.loading = true
        const data = {
          studentid: this.student_id, // 学生ID
        }
        healthStuEyeSearch(data).then(res => {
          this.loading = false
          this.stu_list = res.data.list;
          this.city = res.data.city;
        }).catch(err => {
          console.log(err)
        })
      },
      detail_up() { // 更新信息
        // console.log(12313);
        localStorage.setItem('Route', 'health') // 存跳转标识
        this.$router.push({
          name: 'health_update',
          params: {
            student_id: this.student_id,
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .headerinfos {
    display: flex;

    p {
      color: burlywood;

    }

    p:nth-child(3) {
      cursor: pointer;
      color: black;
    }
  }
</style>
