<template>
	<el-dialog title="删除资质" :visible.sync="visible" width="30%" :before-close="onCancel">
		<span>是否确定删除这条资质？</span>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onCancel">取 消</el-button>
			<el-button type="primary" @click="onConfirm">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		qualificationsDel
	} from '@/api/agentApi.js'
	import {Message} from 'element-ui'
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				required: true
			}
		},
		emits: ['cancel', 'ok'],
		methods: {
			onCancel() {
				this.$emit('cancel')
			},
			onConfirm() {
				qualificationsDel({
					id: this.id
				}).then(res => {
					if (res.status.code != 1) {
						Message.error(res.status.msg)
						return
					}
					this.$emit('ok')
				}).catch(err=>{
					console.log(err)
				})
			}
		}
	}
</script>

<style>
</style>