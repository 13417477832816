import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false,
    status: '' || localStorage.getItem('InfoMessage'),
    userinfo: {},
    pApply:0,
    sApply :0,
    scIndexs :0,
    teaIndexs :0,
    agentIndexs :0,
  },
  getters: {
    Status: (state) => state.status,
    parApply: (state) => state.pApply,
    stuApply: (state) => state.sApply,
    scIndex: (state) => state.scIndexs,
    teaIndex: (state) => state.teaIndexs,
    agentIndex: (state) => state.agentIndexs
  },
  mutations: {
    switchCollapse (state) {
      state.isCollapse = !state.isCollapse
    },
    handleInfoCode (state, Code) {
      state.status = Code
      localStorage.setItem('InfoMessage', Code)
    }
  },
  actions: {
  },
  modules: {
  }
})
