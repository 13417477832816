<template>
	<div id="body">
		<!-- 近期消费折线图结束 -->
		<!-- 账户记录开始 -->
		<div class="bottomBox">
			<!-- 账户记录头 -->
			<div class="headerBox">
				<p>资质管理</p>
			</div>
			<div class="search-bar">
				<div>资质名称：
					<el-select filterable v-model="certName" clearable>
						<el-option v-for="item in certNameOptions" :key="item.title" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div>资质类型：
					<el-select v-model="certType" clearable>
						<el-option v-for="item in certTypeOptions" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</div>
				<div><el-button type="primary" @click="adverList({}, 1, pagesize)">搜索</el-button>
					<el-button type="primary" @click="onUpload">上传资质</el-button>
				</div>

			</div>
			<!-- 账户记录列表 -->
			<el-table :data="adverData" v-loading="loading">
				<el-table-column prop="push_title" label="推送标题" align="center"></el-table-column>
				<el-table-column prop="type" label="资质类型" align="center"></el-table-column>
				<el-table-column prop="period" label="有效期" align="center">
					<template slot-scope="scope">
						{{scope.row.period == 1 ? '长期' : scope.row.period}}
						</template>
				</el-table-column>
				<el-table-column label="资质图片" align="center">
					<template slot-scope="scope">
						<div style="display:flex;">
							<el-image :src="item" v-for="item in scope.row.img.split(',')"
								style="width: 50px; height: 50px;margin: 0 5px" fit="fill"></el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button @click="onEdit(scope.row)" type="text" size="small">编辑</el-button>
						<el-button @click="onDel(scope.row)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
				<!--        <el-table-column prop="stage" label="账户余额" align="center"></el-table-column> -->
			</el-table>
			<!-- 账户记录列表分页 -->
			<div class="block">
				<el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
					:current-page="currpage" :page-size="pagesize" background
					layout="total,prev, pager, next" :total="CountNum" style="margin-top:20px">
				</el-pagination>
			</div>
		</div>
		<!-- 账户记录结束 -->
		<delete-certification-dialog @cancel="delDialogVisible=false" @ok="onUpdate" :visible="delDialogVisible"
			:id="Number(currentData.id)" v-if="currentData!=null"></delete-certification-dialog>
		<certification-form-dialog :data="currentData" @cancel="formDialogVisible=false" @ok="onUpdate" v-if="formDialogVisible" :certTypeOptions="certTypeOptions"></certification-form-dialog>
	</div>
</template>

<script>
	import {
		qualificationsType,
		qualificationsList,
		advShowAmount
	} from '@/api/agentApi.js'
	import deleteCertificationDialog from './components/deleteCertificationDialog.vue'
	import certificationFormDialog from './components/certificationFromDialog.vue'
	export default {
		components: {
			deleteCertificationDialog,
			certificationFormDialog
		},
		data() {
			return {
				formDialogVisible: false,
				adverData: [], // 广告列表
				dateList: ['近7天', '近14天', '近30天'], // Table选项
				pagesize: 99999, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				currentIndex: 0, // 索引
				serchList: {}, //  查询条件
				stateList: [], //  计划名称
				loading: false, //表格加载框
				dcLoading: false, //折线图加载框
				delDialogVisible: false,
				currentData: null,
				certTypeOptions: [],
				certNameOptions: [],
				certType: '',
				certName: ''
			}
		},
		mounted() {
			this.getCertTypes()
			this.adverList({}, 1, this.pagesize) // 获取广告列表
		},
		methods: {
			onEdit(row) {
				this.currentData = row
				this.$nextTick(()=> {
				this.formDialogVisible = true
				}
				)
			},
			onUpload() {
				this.currentData = null
				this.formDialogVisible = true
			},
			getCertTypes() {
				qualificationsType().then(res => {
					this.certTypeOptions = res.data
				})
			},
			onUpdate() {
				this.formDialogVisible = false
				this.delDialogVisible = false
				this.adverList({}, this.currpage, this.pagesize)
			},
			onDel(row) {
				this.currentData = row
				this.delDialogVisible = true
			},
			drawChart(x, y) { //折线图展示
				var dom = document.getElementById('echar');
				var echar = this.$echarts.init(dom);
				var option = {
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['近期消费']
					},
					xAxis: {
						type: 'category',
						data: x,
						axisTick: {
							show: false
						},
					},
					yAxis: {
						type: 'value',
						axisPointer: {
							show: true,
							triggerTooltip: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#e8e8e8'
							}
						},
						// min: 1000,
						// max: 6000
					},
					series: [{
						name: '近期消费',
						data: y,
						type: 'line',
						itemStyle: {
							color: '#1890FF'
						}
					}]
				};
				echar.setOption(option);
				setTimeout(function() {
					window.onresize = function() {
						echar.resize();
					}
				}, 200)
			},
			advShowAmount(num) { // 折线图日期数据
				this.dcLoading = true
				const data = {
					lastDays: num // 数据类型 ( 天 )
				}
				advShowAmount(data).then(res => {
					var x = res.data.date // X轴数据(日期)
					var y = res.data.sum // Y轴数据（总消费）
					this.drawChart(x, y)
					this.dcLoading = false
				}).catch(err => {
					console.log(err)
				})
			},
			Atpitch(index) { // 折线图时间选择索引
				this.currentIndex = index
				switch (index) {
					case 0:
						this.advShowAmount(7)
						break
					case 1:
						this.advShowAmount(14)
						break
					case 2:
						this.advShowAmount(30)
						break
					default:
						this.advShowAmount(7)
				}
			},
			adverList(serchJson, currpage, pageSize) { // 广告列表
				this.loading = true
				const data = serchJson
				data["page"] = currpage //	当前页数
				data["limit"] = pageSize // 每页条数
				data["stage"] = '100' // 审核状态(1待审核 2已通过 3已拒绝 100全部)
				data['type'] = this.certType
				data['title'] = this.certName
				qualificationsList(data).then(res => {
					this.adverData = res.data.list // 广告列表
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.currpage = currpage //分页显示当前页
					this.pagesize = pageSize
					this.loading = false
					this.certNameOptions = this.adverData.map(item => {
						return {
							title: item.push_title,
							id: item.id,
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(num) { // 分页
				this.qualificationsList({}, num, this.pagesize)
			},
			handleSizeChange(num) { //分页设置显示条数
				this.qualificationsList({}, 1, num)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.search-bar {
		padding: 10px 20px;
		display: flex;
		align-items: center;
		justify-content: start;
	}
	
	.search-bar > div {
		margin: 0 20px;
	}

	#body {
		background: #f0f2f5;
	}

	.active {
		//检测人滑动点击css
		color: #1890FF;
		font-size: 14px;
	}

	.topBox {
		width: 100%;
		background: #FFFFFF;
		margin: 0 auto;
		margin-bottom: 20px;

		.chartTitle {
			height: 51px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #E8E8E8;

			.facility {
				font-size: 18px;
				line-height: 48px;
				border-bottom: 2px solid #1890FF;
				color: #1890FF;
				margin-left: 32px;
				font-family: 萍方-简;
				font-weight: normal;
			}

			ul {
				padding: 0;
				margin: 0;
				font-size: 14px;
				margin-right: 25px;

				li {
					float: left;
					list-style: none;
					padding-left: 33px;
				}

				li:hover {
					cursor: pointer
				}
			}
		}
	}

	.bottomBox {
		padding-bottom: 40px;
		width: 100%;
		display: flex;
		background: #FFFFFF;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #E8E8E8;

			p {
				line-height: 49px;
				font-size: 18px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #1890FF;
				margin-left: 32px;
				border-bottom: 2px solid #1890FF;
			}
		}
	}
</style>