<template>
  <div id="body">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!--            <el-breadcrumb-item :to="{ path: '/SchoolHome' }"
                >首页</el-breadcrumb-item
            > -->
      <el-breadcrumb-item>设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column label=" " width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label=" " width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.val }}</span>
        </template>
      </el-table-column>

      <el-table-column label=" "  width="200">

        <template slot-scope="scope">
          <el-button v-if="scope.row.k < 3" size="mini" @click="edit_info(scope.row.k)">修改</el-button>
          <el-button v-if="scope.row.k ==4 && Status =='teacher'" size="mini" @click="relieveBind" >解除</el-button>
          <el-button v-else-if="scope.row.k ==3 " size="mini" @click="operation_log()">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="解除关系" :visible.sync="relieve" top="30px" :close-on-click-modal="false">
      <span>是否确认解除关系 此操作将注销您的账号</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="relieve = false">取 消</el-button>
        <el-button type="primary" @click="affirmbd">下一步</el-button>
      </span>
    </el-dialog>

    <el-dialog title="解除绑定" :visible.sync="verification" top="30px" :close-on-click-modal="false">
      <span>手机<p style="color:#1890FF;display:inline">{{tel}}</p></span>
      <div class="codies">
        <p>请输入验证码：</p>
        <el-input v-model="Checkcode" style="width: 300px;" placeholder="请输入验证码"></el-input>
        <el-button type="primary" @click="sendYZM" v-show="show" style="margin-left: 16px;">发送验证码</el-button>
        <span class="sendyzm" v-show="!show" style="color: #cfcfcf">
          {{ count + "秒" + "后重发"}}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verification = false">取 消</el-button>
        <el-button type="primary" @click="remove">解除绑定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
  import {
    optionInfo,
    exitSchool
  } from "@/api/setUser.js";
  import {
    sendSmsNew,
  } from '@/api/TeacherApi.js'

  import {
    mapGetters
  } from "vuex";
  export default {
    props: ["isCollapse"],
    data() {
      return {
        password: "",
        tel: "",
        rtel: "",
        tableData: [],
        relieve: false, // 解除关系
        verification : false,
        timer: null, // 验证码标识
        count: '',
        show: false, // 倒计时(秒)
        Checkcode : ''//验证码
      };
    },
    computed: {
      ...mapGetters(["Status"]),
    },
    mounted() {
      this.optionInfo();
    },
    methods: {
      // 修改
      edit_info(k) {
        // k=1账号密码  k=2绑定手机
        if (k == 1) {
          this.$router.push({
            name: "edit_pass",
            params: {
              tel: this.tel,
              rtel: this.rtel,
            },
          });
        }
        if (k == 2) {
          this.$router.push({
            name: "edit_tel",
            params: {
              tel: this.tel,
            },
          });
        }
      },
      // 操作记录
      operation_log() {
        this.$router.push({
          name: "operation_log",
        });
      },
      relieveBind() { // 解除关系
        this.relieve = true // 打开弹窗
      },

      affirmbd() { // 点击下一步
        const data = {
          phone: this.rtel,
          type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        }
        sendSmsNew(data).then(res => {
          const res_data =JSON.parse(res.data.data)
          // 重新开始倒计时
          if (res_data.status.code == 1) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.relieve = false
            this.verification = true // 打开验证码弹窗
            this.YZMsend()
          } else {
            this.$message.error(res_data.status.msg);
          }
        }).catch(err => {
          console.log(err)
        })

      },

      // 解除关系
      remove() {
          const data = {
            code : this.Checkcode,
            tel  : this.rtel
          }
          exitSchool(data).then(res => {
            if(res.data == 1){
              this.$message({
                message: "注销成功",
                type: "success",
              });
              localStorage.clear() // 清除session
              this.$router.push({
                path: '/login'
              })
            }else{
              this.$message({
                message: res.status.msg,
                type: "error",
              });
            }


          }).catch(err => {
            console.log(err)
          })
      },
      sendYZM() { // 倒计时结束后发送验证码
        const data = {
          phone: this.rtel,
          type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        }
        // 原来sendSms(data).then(res => { // 重新开始倒计时
        sendSmsNew(data).then(res => { // 重新开始倒计时
          if (res.status.code == 1) {
            this.$message({
              message: '发送成功',
              type: 'success'
            })
            this.YZMsend()
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      YZMsend() { // 验证码
        const TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      },
      // 获取页面显示信息
      optionInfo() {
        optionInfo()
          .then((res) => {
            if (res.status.code == 1) {
              this.password = res.data.password;
              this.tel = res.data.tel;
              this.rtel = res.data.rtel;
              this.zy = res.data.zy;
              if (this.Status == 'school') {
                this.tableData = [{
                    k: 1,
                    name: "账号密码",
                    val: this.password,
                  },
                  {
                    k: 2,
                    name: "绑定手机",
                    val: this.tel,
                  },
                  {
                    k: 3,
                    name: "操作记录",
                    val: " ",
                  },
                ];
              } else {
                this.tableData = [{
                    k: 1,
                    name: "账号密码",
                    val: this.password,
                  },
                  {
                    k: 2,
                    name: "绑定手机",
                    val: this.tel,
                  },
                  {
                    k: 4,
                    name: "工作关系",
                    val: this.zy,
                  },
                  {
                    k: 3,
                    name: "操作记录",
                    val: " ",
                  },
                ];
              }

            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(res);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  #body {
    margin: 30px;
  }
</style>
