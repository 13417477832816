<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>门店列表</p>
      </div>
      <div class="query">
        <div class="queryLeft">
          <div style="width: auto;">门店：
            <el-select class="select" filterable v-model="serchList.id" size="medium" placeholder="请选择门店"
              style="width: auto;">
              <el-option v-for="item in shopData" :key="item.id" :label="item.shopName" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="storeMachineListFun(serchList,1)">查询</el-button>
        </div>
      </div>

      <el-table :data="shopInfos" v-loading="loading">
        <el-table-column prop="serial" label="序号" type="index" align="center" width="100">
          <template slot-scope="scope">
            {{ (currpage - 1) * pagesize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="shop_name" label="名店名称" align="center" width="150">
        </el-table-column>
        <el-table-column prop="ssq" label="门店位置" align="center" width="200">
          <template slot-scope="scope">
            <!-- {{scope.row.pname }} {{scope.row.cname }}-->
            {{scope.row.aname }}{{scope.row.detail_addr }}
          </template>
        </el-table-column>
        <el-table-column prop="eyeNum" label="视力仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="ygyNum" label="验光仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="xlyNum" label="训练仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="qdyNum" label="签到仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="InsertTime" label="加入时间" :formatter="InsertTime" width="160" align="center">
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="120">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName==null?true:false"
              @click="detail(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
  </div>


</template>

<script>
  import {
    storeMachineList,
    machineBind,
    shopList
  } from '@/api/agentApi.js'
  import request from '@/api/request.js'
  export default {
    data() {
      return { //声明属性变量 可以用this.属性名得到 例如 this.pagesize
        shopInfos: [], // 门店列表
        pagesize: 12, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        serchList: {}, //查询列表
        shopData: [], //门店下拉
        dialogFormVisible: false, // 添加设备弹窗
        Addfacility: {
          facility: '' // 添加的设备编号
        },
        file: [], //上传的file数组
        token: localStorage.getItem('token'), //token

      }
    },
    mounted() {
      this.storeMachineListFun({}, 1)  // 获取门店列表
      this.storeMachineListFun2() // 获取门店下拉
    },
    methods: {
      reset() { //重置按钮
        this.serchList = {}
        this.storeMachineListFun({}, 1)
      },
      InsertTime(row, column) { // 加入时间过滤
        return row.InsertTime
        // return row.InsertTime.slice(0, 16)
      },
      storeMachineListFun(serchJson, currpage) { // 门店列表
        this.loading = true
        const data = serchJson

        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数

        storeMachineList(data).then(res => {
          this.shopInfos = res.data.List // 门店列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      storeMachineListFun2() { // 下拉框获取门店列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 1000 // 每页条数
        shopList(data).then(res => {
          this.shopData = res.data.data.map((item, index) => {
            return Object.assign({}, {
              'shopName': item.shop_name
            }, {
              'id': item.id
            })
          })

          this.shopData.unshift({
          	shopName: '全部门店',
          	id: 0
          })
        }).catch(err => {
          console.log(err)
        })
      },
      detail(index, row) { // 点击详情
        //console.log(row);
        this.$router.push({
          name: 'ShopList_detail',
          params: {
            Schinfos: row.id
          }
        })
        localStorage.setItem('lxzShopName', row.shop_name) // 存门店名字
      },
      handleCurrentChange(val) { // 分页
        this.shopList({}, val)
        // this.currpage = val
      },
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;

        div {
          width: 22%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
