<template>
  <div id="body">
    <div class="fatherBox">
      <div class="sqlist">
        <p class="title">家长申请列表</p>
        <el-table :data="ClassInfos" v-loading="loading">
        <el-table-column prop="StuName" label="学生姓名" width="150" align="center"></el-table-column>
        <el-table-column prop="StuGender" label="性别" align="center" >
        </el-table-column>
        <el-table-column prop="stu_id" label="学号" align="center" >
        </el-table-column>
         <el-table-column  label="所在班级" align="center"  >
            <template slot-scope="scope">
                {{scope.row.TypeName}}{{scope.row.ClassName}}
            </template>
          </el-table-column>
        <el-table-column prop="GuaName" label="家长昵称" width="150" align="center"></el-table-column>
        <el-table-column prop="RegisterMobile" label="家长电话" width="150" align="center"></el-table-column>

        <el-table-column prop="intime" label="申请时间" align="center"></el-table-column>

        <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="agree(scope.$index, scope.row,1)">同意</el-button>
              <el-button size="mini" type="danger" @click="agree(scope.$index, scope.row,2)">拒绝</el-button>
            </template>
         </el-table-column>
        </el-table>
        <div class="block" v-show="CountNum > 0">
          <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
            layout="prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top: 20px">
          </el-pagination>
        </div>

      </div>

      <div class="sqlist">
        <p class="title">审批记录</p>
        <el-table :data="SpInfos" v-loading="sloading">
         <el-table-column prop="StuName" label="学生姓名" width="150" align="center"></el-table-column>
         <el-table-column prop="StuGender" label="性别" align="center" >
         </el-table-column>
        <el-table-column prop="stu_id" label="学号" align="center" >
        </el-table-column>
          <el-table-column  label="所在班级" align="center"  >
             <template slot-scope="scope">
                 {{scope.row.TypeName}}{{scope.row.ClassName}}
             </template>
           </el-table-column>
         <el-table-column prop="GuaName" label="家长昵称" width="150" align="center"></el-table-column>
         <el-table-column prop="RegisterMobile" label="家长电话" width="150" align="center"></el-table-column>

         <el-table-column prop="intime" label="申请时间" align="center"></el-table-column>
          <el-table-column prop="approve_time" label="审批时间" align="center"></el-table-column>
          <el-table-column prop="gtatus" label="审批结果" align="center"></el-table-column>
          <el-table-column prop="approve_person" label="审批人" align="center"></el-table-column>
          <!--
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="agree(scope.$index, scope.row)">同意</el-button>
              <el-button size="mini" type="danger" @click="Refused(scope.$index, scope.row)">拒绝</el-button>
            </template>
          </el-table-column>
          -->
        </el-table>
        <div class="block" v-show="ScountNum > 0">
          <el-pagination @current-change="shandleCurrentChange" :page-size="pagesize" background
            layout="total,prev, pager, next, jumper" :current-page="scurrpage" :total="ScountNum" style="margin-top: 20px">
          </el-pagination>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import {
    stuapply,
    stuapplycheck
  } from '@/api/user.js'
  import {
    guaApplyeedit,
    stuApplyed,
    guaApplyelist,
    guaApplyed
  } from '@/api/TeacherApi.js'
  export default {
    data() {
      return {
        ClassInfos: [],
        SpInfos:[],
        ScountNum:0,
        scurrpage: 1,
        sloading: false, //加载框

        pagesize: 3, // 每页显示条目个数
        currpage: 1, // 初始页

        CountNum: 0, // 总条数
        loading: false, //加载框
        StuID: '' // 学生ID
      }
    },
    mounted() {
      this.ApplyList(1) // 获取申请列表
      this.getstuApplyed(1)
    },
    methods: {
      agree(index, row ,type) {
        var agreemsg = '已拒绝'
        if(type == 1){
          var agreemsg = '已同意'
        }
        // 同意
        const data = {
          id        : row.gid, // ID
          status    : type ,// 1 同意 2拒绝
        }

        guaApplyeedit(data)
          .then((res) => {
            if (res.status.code == 1) {
              this.$message({
                message: agreemsg,
                type: 'success'
              })
              this.ApplyList(1) // 获取申请列表
              this.getstuApplyed(1)
              this.$store.state.pApply = 10
              this.ClassInfos.splice(this.ClassInfos.indexOf(index), 1)
            } else {
              this.$message.error(res.status.msg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      Gender(row, column) {
        // 筛选性别
        return row.StuGender == 1 ? '男' : '女'
      },
      transfer(row, column) {
        // 筛选是否从其他学校转入
        return row.SchoolName == '' ? '否' : '是'
      },
      getstuApplyed(scurrpage){
        this.sloading = true
        // 学生申请列表
        const data = {
          page: scurrpage, //	当前页数
          limit: this.pagesize //	每页条数
        }
        guaApplyed(data)
         .then((res) => {
           this.SpInfos = res.data.List
           this.ScountNum = parseInt(res.data.CountNum) // 总条数
           this.sloading = false
         })
         .catch((err) => {
           console.log(err)
         })
      },
      ApplyList(currpage) {
        this.loading = true
        // 学生申请列表
        const data = {
          page: currpage, //	当前页数
          limit: this.pagesize //	每页条数
        }
        // stuapply(data)
        //   .then((res) => {
        //     // console.log(res)
        //     this.ClassInfos = res.data.List
        //     this.CountNum = parseInt(res.data.CountNum) // 总条数
        //     this.loading = false
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
          guaApplyelist(data)
            .then((res) => {
              console.log(res,'家长申请列表')
              this.ClassInfos = res.data.List
              this.CountNum = parseInt(res.data.CountNum) // 总条数
              this.loading = false
            })
            .catch((err) => {
              console.log(err)
            })

      },
      handleCurrentChange(val) {
        this.ApplyList(val)
        this.currpage = val
      },

      shandleCurrentChange(val) {
        this.getstuApplyed(val)
        this.scurrpage = val
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title{
    font-size: 30px !important;
    margin-right: 55% !important;
        width: 100%;
        text-align: left;
  }
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 820px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }
    }
  }
</style>
