<template>
  <div id="body">
    <div class="header">
      <!-- <p class="hometext">首页</p> -->
      <div class="hader_title">
        <div style="width: 100px;">
          <img src="../../assets/APP_logo.png" alt=""/>
<!--          <div>
            <el-popover placement="top-start" title="系统联系人" width="70" trigger="hover" content="" style="margin-right: 16px;">
              <span>{{this.agentAdmin.name}}</span>
              <br>
              {{this.agentAdmin.tel}}
              <el-image src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTY0NjYyMjkyOC45NTYxLjExNjc3"
                slot="reference"></el-image>
            </el-popover>
            <el-popover title="微信二维码" placement="top-start" width="70" trigger="hover" content="">
              <el-image style="width: 100px; height: 100px ;margin-left: 10px;" :src="this.agentAdmin.pic"></el-image>
              <br>
              <el-image src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTY0NjYyMjkyMy43NjIzLjQ0NDc5"
                slot="reference"></el-image>
            </el-popover>
          </div> -->
        </div>
        <div class="title_text">
          <p>{{ ClassName }}</p>
          <p>ID:{{ ClassID }}</p>
          <p>班主任：{{ ClassAdmin }}</p>
        </div>
      </div>
      <div class="datalist">
        <div>
          <p style="color: #ff5a21">{{SchoolRank}}</p>
          <p>本校排名</p>
        </div>
        <div>
          <p style="color: #3aa0ff">{{ClassTypeRank}}</p>
          <p>同级排名</p>
        </div>
        <div>
          <p style="color: #1ac574">{{CityRank}}</p>
          <p>同城排名</p>
        </div>
        <div>
          <p style="color: #30cadc">{{AllRank}}</p>
          <p>全部排名</p>
        </div>
      </div>
      <div id="circle" style="width: 200px; height: 200px;margin-right: 5vw;margin-top: 10px;"></div>
      <!-- <p class="goup">返回</p>  -->
      <el-button type="primary" round class="goup" v-if="Infostatus=='school'?true:false" @click="$router.back(-1)">返回
      </el-button>
      <!-- <div class="Switch">
        <p>是否允许加入班级：</p>
        <el-switch
          class="switch"
          v-model="Switch_on"
          active-color="#1890FF"
          inactive-color="#F0F2F5"
          :width=45>
        </el-switch>
      </div> -->
    </div>

    <div class="ClassInfo_box">
      <div class="ranking">
        <ul class="ranking">
          <li v-for="(item, index) in rankingList" :key="index" @click="Atpitch(index)"
            :class="currentIndex == index ? 'active' : ''">
            {{ item }}
          </li>
        </ul>
        <p class="peoples">本月测试人数:{{detection}}</p>
      </div>
      <div v-if="currentIndex==4" class = "query" style="width: 98%;">
        <div v-for="(item,index) in tabList" :key="index" style="margin-right:10px;">
          <el-button size="medium" round :class="currentTabIndex == index ? 'activeTab' : ''"  @click="AtpitchTab(index)">{{item}}</el-button>
        </div>
        <div class="seach">
				<span>时间：</span>
				<el-date-picker class="time" size="medium" type="daterange" v-model="picker" start-placeholder="开始日期"
					range-separator="-" end-placeholder="结束日期"  value-format="yyyy-MM-dd" :default-time="['00:00:00','23:59:59']"
					unlink-panels @change="pickerChange" :picker-options="pickerOptions">
				</el-date-picker>
			</div>
      </div>
      <div id="main" style="width: 98%; height: 550px; background: white;"></div>
<!--      <div id="main1" style="width: 98%; height: 550px; background: white;" ></div> -->
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import {
  agentAdmin,
} from '@/api/agentApi.js'
import {
  statistics,
  classNunChart,
  classMyopiaChart,
  classMonthNew,
  classTotalNew,
  classEyepic
} from '@/api/TeacherApi.js'
export default {
  data () {
    return {
      agentAdmin: {}, // 系统联系人
      ClassName: '',
      TeacherID: '51212424556',
      ClassAdmin: '',
      ClassID: '', // 班级ID
      ClassType: '', // 年级ID
      rankingList: [
        '班级检测人数',
        '班级近视人数',
        '本月新增近视人数',
        '本月新增恢复人数',
        '视力检测'
        // '累计近视人数',
        // '累计恢复人数',

      ], // Table选项
      SchoolRank: '', // 本校排名
      ClassTypeRank: '', // 同级排名
      CityRank: '', // 同城排名
      AllRank: '', // 全部排名
      currentIndex: 0,
      currentTabIndex: 0,
      detection: '', // 本月测试人数
      Switch_on: false, // 是否允许加入班级
      Series: [], // 折线图Y轴数据
      Dates: [], // 折线图X轴数据
      Infostatus: '', // 身份标识
      Seriesclhl: '',
      Seriesglhl: '',
      Seriesslhl: '',
      Seriesalhl: '',
      tabList: [
        '近3天',
        '近7天',
        '近30天'
      ],
      dataType: 1,
      picker: null, // 查询的日期
      startDate: null,
      endDate: null,
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({
          maxDate,
          minDate
        }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          let choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
    }
  },
  computed: {
    ...mapGetters(['Status'])
  },
  mounted () {
    // this.AgentAdmin() // 获取系统联系人
    const LocalClassID = JSON.parse(localStorage.getItem('userInfo'))
    this.ClassID   = this.$route.params.ClassID == undefined ? LocalClassID.ClassID : this.$route.params.ClassID
    this.ClassType = this.$route.params.ClassType == undefined ? LocalClassID.ClassID : this.$route.params.ClassType
    this.ClassName = this.ClassID == 0 ? '暂无班级' : this.ClassName
    // console.log(LocalClassID.ClassID);
    this.DataList() // 获取班级近视数
    this.ClssNum() // 默认展示班级人数的数据
    // document.getElementById('main1').style  ='none'
    // this.ClassID = this.$route.params.ClassID; // 从学校跳转教师接收到的值
    // if(this.$route.params.ClassID != 'undefined'){
    //   this.ClassID = this.$route.params.ClassID
    // }
    this.classEyepic()//画视力检测折线图
    var identity = localStorage.getItem('InfoMessage')
    this.Infostatus = identity
    // this.circleEcharts()
  },
  methods: {
    AgentAdmin() { // 获取系统联系人
      agentAdmin().then(res => {
        this.agentAdmin = res.data.list;
      }).catch(err => {
        console.log(err)
      })
    },
    drawChart () {
      const that = this
      const myChart = this.$echarts.init(document.getElementById('main'))
      myChart.clear()
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['人数']
        },
        xAxis: {
          data: that.Dates
        },
        yAxis: {},
        series: [{
          name: '人数',
          type: 'line',
          data: that.Series
        }]
      }
      myChart.setOption(options)
    },
    Atpitch (index) { // Tab
      // console.log(index);
      this.currentIndex = index
      // var main = document.getElementById('main');
      // var main1 = document.getElementById('main1');

      if(index < 4){
        // main.display  = 'show';
        // main1.display = 'none';
      }else if(index == 4){
        // main.display  = 'none';
        // main1.display = 'show';
      }

      switch (index) {
        case 0:
          this.ClssNum()
          break
        case 1:
          this.myopic()
          break
        case 2:
          this.classMonthNew(1)
          break
        case 3:
          this.classMonthNew(2)
          break
        case 4:
          this.classEyepic()
          break
        case 5:
          this.classTotalNew(1) // 累计近视人数
          break
        case 6:
          this.classTotalNew(2) // 累计恢复人数
          break

        default:
          this.ClssNum()
      }
    },
    circleEcharts () {
      var myChart = this.$echarts.init(document.getElementById('circle'))
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          show: false
        },
        series: [{
          name: '近视程度',
          type: 'pie',
          radius: ['40%', '70%'],
          labelLine: {
            show: false
          },
          label: {
            show: false
          },
          data: [{
            value: 1048,
            name: '轻度近视',
            itemStyle: {
              color: '#FAC858'
            }
          },
          {
            value: 735,
            name: '中度近视',
            itemStyle: {
              color: '#73C0DE'
            }
          },
          {
            value: 580,
            name: '重度近视',
            itemStyle: {
              color: '#EE6666'
            }
          },
          {
            value: 484,
            name: '不近视',
            itemStyle: {
              color: '#91CC75'
            }
          }
          ]
        }]
      }
      option && myChart.setOption(option)
    },
    DataList () { // 首页数据统计
      const data = {
        ClassID: this.ClassID
      }
      statistics(data).then(res => {
        // console.log(res);
        if (res.status.code == 1) {
          this.ClassName = res.data.ClassTypeName + res.data.ClassName // 班级名称
          // this.TeacherID = res.data.ClassID// 班级ID
          this.ClassAdmin = res.data.TeaName == '' ? '暂无' : res.data.TeaName // 班主任
          this.SchoolRank = res.data.SchoolRank // 本校
          this.ClassTypeRank = res.data.ClassTypeRank // 同级
          this.CityRank = res.data.CityRank // 同城
          this.AllRank = res.data.AllRank // 全部
        } else {
          console.log('失败')
          this.ClassName = '暂无'
          this.ClassAdmin = '暂无'
          this.SchoolRank = '暂无'
          this.ClassTypeRank = '暂无'
          this.CityRank = '暂无'
          this.AllRank = '暂无'
        }
      }).catch(err => {
        console.log(err)
      })
    },
    ClssNum () { // 班级人数
      const data = {
        ClassID: this.ClassID
      }
      classNunChart(data).then(res => {
        // console.log(res.data);
        const Dates = new Date()
        var NewMonth = Dates.getMonth() + 1
        // console.log(Dates.getMonth() + 1);
        this.Series = res.data.num
        this.Dates = res.data.date

        this.detection = res.data.num[NewMonth - 1] // 本月测试人数

        this.drawChart() // 执行Echarts
      }).catch(err => {
        console.log(err)
      })
    },
    myopic () { // 班级近视人数
      // console.log(this.TeacherID);
      // console.log(this.ClassID);
      const data = {
        // ClassID: this.ClassID == 'undefined'?this.TeacherID:this.ClassID
        ClassID: this.ClassID
      }
      classMyopiaChart(data).then(res => {
        // console.log(res);
        this.Series = res.data.num
        this.Dates = res.data.date
        this.drawChart()
      }).catch(err => {
        console.log(err)
      })
    },
    classMonthNew (type) { // 本月新增近视人数/本月新增恢复人数
      const data = {
        ClassID: this.ClassID,
        DataType: type // 1近视 2恢复
      }
      classMonthNew(data).then(res => {
        // console.log(res)
        this.Series = res.data.num
        this.Dates = res.data.date
        this.drawChart()
      }).catch(err => {
        console.log(err)
      })
    },
    classTotalNew (type) { // 累计近视人数/累计恢复人数
      const data = {
        ClassID: this.ClassID,
        DataType: type // 1近视 2恢复
      }
      classTotalNew(data).then(res => {
        // console.log(res)
        this.Series = res.data.num
        this.Dates = res.data.date
        this.drawChart()
      }).catch(err => {
        console.log(err)
      })
    },
    AtpitchTab (index) {
      this.currentTabIndex = index
      this.dataType = index + 1
      this.classEyepic()
    },
    pickerChange (e) { // 选择日期
      console.log(e)
      this.dataType = 4
      this.currentTabIndex = 3
      const datestart = new Date(e[0] + ' 00:00:00')
      const dateend = new Date(e[1] + ' 00:00:00')
      this.startDate = datestart.getTime() / 1000
      this.endDate = dateend.getTime() / 1000
      this.classEyepic()
    },
    classEyepic () {
      const data = {
        dataType: this.dataType, // 时间类型 默认1  4的时候添加开始时间 结束时间 时间戳
        startDate: this.startDate,
        endDate: this.endDate,
        ClassType: this.ClassType,
        ClassID: this.ClassID
      }
      classEyepic(data).then(res => {
        this.Seriesclhl = res.data.clhl
        this.Seriesglhl = res.data.glhl
        this.Seriesslhl = res.data.slhl
        this.Seriesalhl = res.data.alhl
        this.Dates = res.data.date
        this.drawCharts()
      }).catch(err => {
        console.log(err)
      })
    },

    drawCharts () {
      const that = this
      const myChart = this.$echarts.init(document.getElementById('main'))
      myChart.clear()
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['本班近视率', '同年级近视率', '全校近视率', '全区近视率']
        },
        xAxis: {
          data: that.Dates
        },
        yAxis: {},
        series: [
          {
            name: '本班近视率',
            type: 'line',
            data: that.Seriesclhl
          },
          {
            name: '同年级近视率',
            type: 'line',
            data: that.Seriesglhl
          },
          {
            name: '全校近视率',
            type: 'line',
            data: that.Seriesslhl
          },
          {
            name: '全区近视率',
            type: 'line',
            data: that.Seriesalhl
          }
        ]
      }
      myChart.setOption(options)
    }
  }
}
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    overflow: hidden;
    background: #f0f2f5;
  }

  .header {
    display: flex;
    height: 150px;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    // position: relative;

    // .hometext {
    //   align-self: flex-start;
    //   font-size: 14px;
    //   font-family: PingFangSC-Regular;
    //   color: rgba(0, 0, 0, 0.45);
    // }
    .hader_title {
      display: flex;
      margin-left: 5px;
      align-items: center;
      margin-left: 2vw;

      .title_text {
        margin-left: 24px;

        p:nth-child(1) {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          font-family: PingFangSC-Medium;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 14px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.85);
        }

        p:nth-child(3) {
          font-size: 14px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
    }

    .datalist {
      display: flex;
      width: 473px;
      height: 70px;
      // margin-left: 200px;
      justify-content: space-around;

      div {
        p:nth-child(1) {
          font-size: 20px;
          font-weight: normal;
        }

        p:nth-child(2) {
          font-size: 14px;
          font-weight: normal;
          color: #000000;
        }
      }
    }

    .Switch {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 478px;

      p {
        font-size: 14px;
        line-height: 22px;
        color: #8C8C8C;
        font-family: PingFangSC-Regular;
      }
    }

    .goup {
      margin-right: 5vw;
      margin-top: -100px;
      // right: 65px;
      // top: 5px;
      // font-size: 21px;
      // color:dodgerblue;
      // position: absolute;
    }
  }

  .ClassInfo_box {
    width: 98%;
    margin: 0 auto;
    margin-top: 25px;

    .ranking {
      width: 98%;
      display: flex;
      align-items: center;
      height: 68px;
      background: #ffffff;
      // background:yellowgreen;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;

      ul {
        padding: 0;
        margin: 0;
        width: 590px;

        li {
          list-style: none;
          font-size: 12px;
          font-weight: normal;
          // color: #575757;
          font-family: 萍方-简;
          margin-left: 18px;
          margin-top: 6px;
        }

        li:hover {
          cursor: pointer
        }
      }

      .peoples {
        margin-right: 50px;
        background: #1890FF;
        font-size: 14px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #FFFFFF;
        height: 25px;
        line-height: 25px;
        padding: 0 7px;
      }
    }
  }

  .active {
    //检测人滑动点击css
    display: flex;
    color: #1890FF;
    font-size: 16px;
    font-weight: 500;
    padding: 25px 0 23px 0;
    border-bottom: 2px solid #1890FF;
  }
  .activeTab{
    color: #FFFFFF;
    background:#1890FF ;
  }
  .query{
    display: flex;
    width: 100%;
    height: 80px;
    background: #ffffff;
    padding: 20px;
  }
  .seach {
			display: flex;
			align-items: center;

			.time {
				margin-left: 20px;
				width: 300px;
			}
		}
</style>
