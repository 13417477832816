<template>
  <div class="body">
    <div class="info" style="margin-left: 100px; font-size: 32px; font-weight: bold">
      设置
    </div>
    <div style="margin-left: 100px; margin-top: 10px">
      <div class="PWD">
        <div class="password">
          <p>账号密码</p>
        </div>
        <span style="color: #1890ff; cursor: pointer" @click="amendPwd">修改</span>
      </div>
      <div class="Mobile">
        <div class="Tel">
          <p>绑定手机号</p>
        </div>
        <span style="color: #1890ff; cursor: pointer" @click="bindMobile">修改</span>
      </div>
      <div class="Mobile">
        <div class="Tel">
          <p>用户信息</p>
        </div>
        <span style="color: #1890ff; cursor: pointer" @click="see">查看</span>
      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="ChangePwd" top="30px">
      <el-form :model="up_pass" ref="articleForm" style="margin-left: 100px">
        <span style="display: inline; font-size: 20px; font-weight: bold">提现需要向手机 {{ Mobile }}发送验证码</span>
        <el-form-item label="请输入验证码:" style="margin-top: 15px">
          <el-input v-model="up_pass.code" style="width: 280px; float: left"></el-input>
          <span class="sendyzm" @click="sendYZM" v-show="show">发送验证码</span>
          <span class="sendyzm" v-show="!show" style="color: #cfcfcf">
            {{ count + "秒" + "后重发" }}
          </span>
        </el-form-item>
        <el-form-item label="请输入新密码:">
          <el-input v-model="up_pass.pass" type="password" style="width: 400px; float: left"></el-input>
        </el-form-item>
        <el-form-item label="请再次输入新密码:">
          <el-input v-model="up_pass.password" type="password" style="width: 375px; float: left"></el-input>
        </el-form-item>
        <el-form-item style="margin: 0 auto">
          <el-button @click="ChangePwd = false" style="margin-left: 20px">取消</el-button>
          <el-button type="primary" @click="affirm_ok" style="margin-left: 180px">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="pwd_success" top="30px">
      <el-form :model="up_pass" ref="articleForm" style="margin-left: 100px">
        <span style="display: inline; font-size: 20px; font-weight: bold; margin-left: 160px">密码修改成功</span>
        <el-form-item style="margin: 0 auto; margin-top: 40px">
          <el-button type="primary" @click="pwd_success = false" style="margin-left: 180px">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="更换手机号" :visible.sync="bind_mobile" top="30px">
      <el-form :model="bing_mobile_arr" ref="articleForm" style="margin-left: 200px">
        <el-form-item label="请输入当前手机号:">
          <el-input v-model="bing_mobile_arr.before_mobile" type="text" style="width: 400px; float: left"
            :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="请输入新的手机号:">
          <el-input v-model="bing_mobile_arr.new_mobile" type="text" style="width: 400px; float: left"></el-input>
        </el-form-item>
        <el-form-item style="margin: 0 auto">
          <el-button @click="bind_mobile = false" style="margin-left: 20px">取消</el-button>
          <el-button type="primary" @click="bing_ok" style="margin-left: 280px">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改手机号" :visible.sync="bind_mobile_send" top="30px">
      <el-form :model="bing_mobile_arr" ref="articleForm" style="margin-left: 200px">
        <span style="
            display: inline;
            font-size: 20px;
            font-weight: bold;
            margin: 0 auto;
            margin-left: 40px;
          ">验证码发送至 {{ bing_mobile_arr.new_mobile }}发送验证码</span>

        <el-form-item label="请输入验证码:" style="margin-top: 15px">
          <el-input v-model="bing_mobile_arr.bing_code" style="width: 200px; float: left"></el-input>
          <span class="bing_sendyzm" @click="bing_sendYZM" v-show="bing_show">发送验证码</span>
          <span class="bing_sendyzm" v-show="!bing_show" style="color: #cfcfcf">
            {{ bing_count + "秒" + "后重发" }}
          </span>
        </el-form-item>
        <el-form-item style="margin: 0 auto">
          <el-button @click="bind_mobile_send = false" style="margin-left: 20px">取消</el-button>
          <el-button type="primary" style="margin-left: 280px" @click="bing_up">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    sendSmsNew
  } from "@/api/TeacherApi.js";
  import {
    passwdChange,
    telChange
  } from "@/api/agentApi.js";

  export default {
    data() {
      return {
        ChangePwd: false, // 修改密码提示弹窗
        Mobile: "",
        regex: /^1[3456789]\d{9}$/, // 手机号正则
        show: true,
        timer: null, // 验证码标识
        count: "",
        Telshow: true,
        bing_Telshow: true,
        bing_show: true,
        bing_timer: "",
        bing_count: "",
        ActiveTel: "", // 当前手机号
        up_pass: {
          code: "",
          pass: "",
          password: "",
        }, // 修改密码数据
        user_id: 0, //用户id
        pwd_success: false, // 修改密码成功
        bind_mobile: false, // 绑定 手机 提示弹窗
        bind_mobile_send: false, //绑定 手机 发送信息 弹窗
        bing_mobile_arr: {
          before_mobile: "",
          new_mobile: "",
          bing_code: "",
        }, //绑定 手机 数据
        new_mobile: '',
      };
    },
    mounted() {
      const user_info = JSON.parse(localStorage.getItem("userInfo"));
      this.user_id = user_info.userId;
      // var new_mobile = user_info.tel
      this.new_mobile = user_info.tel
      // var new_mobile = "13795112596";
      this.ActiveTel = this.new_mobile;
      // new_mobilenpm += "";
      const Tel = this.new_mobile.replace(this.new_mobile.substring(3, 7), "****");
      this.Mobile = Tel;
      this.bing_mobile_arr.before_mobile = this.new_mobile;
      // console.log('111111', this.Mobile);
    },
    methods: {
      amendPwd() {
        // 修改密码
        this.ChangePwd = true; // 打开弹窗
      },
      bindMobile() {
        // 修改手机号
        this.bind_mobile = true; // 打开弹窗
      },
      see() {
        // 查看用户信息跳转
        this.$router.push({
          name: 'user_information',
          params: {}
        })
      },
      //绑定手机 确认
      bing_ok() {
        if (this.bing_mobile_arr.before_mobile == "") {
          this.$message.error("以前不能为空");
        } else if (this.bing_mobile_arr.new_mobile == "") {
          this.$message.error("修改成手机号不能为空");
        } else if (!this.regex.test(this.bing_mobile_arr.new_mobile)) {
          this.$message.error("修改成手机号输入有误");
        } else if (this.bing_mobile_arr.new_mobile == this.bing_mobile_arr.before_mobile) {
          this.$message.error("修改成手机号与以前手机号 一致");
        } else {
          this.bind_mobile_send = true;
          this.bind_mobile = false;
        }
      },
      //修改手机号保存
      bing_up() {
        if (this.bing_mobile_arr.new_mobile == "") {
          this.$message("密码不能为空");
        } else if (this.bing_mobile_arr.bing_code == "") {
          this.$message("验证码不能为空");

        } else {
          const data = {
            code: this.bing_mobile_arr.bing_code,
            newtel: this.bing_mobile_arr.new_mobile,
            GUserID: this.user_id,
          };
          telChange(data)
            .then((res) => {
              // console.log(res)
              if (res.status.code == 1) {
                this.new_mobile =this.bing_mobile_arr.new_mobile;
                this.$message({
                  message: "修改成功",
                  type: "success",
                });

                this.bing_mobile_arr = {
                  before_mobile: this.bing_mobile_arr.new_mobile,
                  new_mobile: "",
                  bing_code: "",
                };
                // this.Mobile = this.bing_mobile_arr.before_mobile;
                this.ActiveTel = this.bing_mobile_arr.before_mobile;
                const Tel = this.ActiveTel.replace(this.ActiveTel.substring(3, 7), "****");
                this.Mobile = Tel;
                // this.Mobile =this.Mobile.replace(this.bing_mobile_arr.before_mobile.substring(3, 7), "****");
                this.bind_mobile_send = false;
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              this.$message.error(res.status.msg);
            });
        }
      },
      //修改密码
      affirm_ok() {
        // 修改密码二次确定

        if (this.up_pass.pass == "") {
          this.$message("密码不能为空");
        } else if (this.up_pass.password == "") {
          this.$message("确认密码不能为空");
        } else if (this.up_pass.code == "") {
          this.$message("验证码不能为空");
        } else {
          const data = {
            code: this.up_pass.code,
            password: this.up_pass.pass,
            tel: this.ActiveTel,
            GUserID: this.user_id,
          };

          passwdChange(data)
            .then((res) => {
              // console.log(res)
              if (res.status.code == 1) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.up_pass = {
                  code: "",
                  pass: "",
                  password: "",
                };
                this.pwd_success = true;
                this.ChangePwd = false;
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              console.log(res);
            });
        }
      },
      sendYZM() {
        // 点击发送验证码
        const data = {
          phone: this.ActiveTel,
          type: 3, // 短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        };
        sendSmsNew(data)
          .then((res) => {
            const res_data =JSON.parse(res.data.data)
            // 重新开始倒计时
            if (res_data.status.code == 1) {
              this.$message({
                message: "发送成功",
                type: "success",
              });
              this.YZMsend();
            } else {
              this.$message.error(res_data.status.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      YZMsend() {
        // 验证码
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.Telshow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              this.Telshow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      },
      bing_sendYZM() {
        // 点击发送验证码
        const data = {
          phone: this.bing_mobile_arr.new_mobile,
          type: 3, // 短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        };
        sendSmsNew(data)
          .then((res) => {
           const res_data =JSON.parse(res.data.data)
            // 重新开始倒计时
            if (res_data.status.code == 1) {
              this.$message({
                message: "发送成功",
                type: "success",
              });
              this.bing_YZMsend();
            } else {
              this.$message.error(res_data.status.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      bing_YZMsend() {
        // 验证码
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.bing_count = TIME_COUNT;
          this.bing_show = false;
          this.bing_Telshow = false;
          this.bing_timer = setInterval(() => {
            if (this.bing_count > 0 && this.bing_count <= TIME_COUNT) {
              this.bing_count--;
            } else {
              this.bing_show = true;
              this.bing_Telshow = true;
              clearInterval(this.bing_timer);
              this.bing_timer = null;
            }
          }, 1000);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  * {
    text-align: left;
  }

  .PWD {
    display: flex;
    width: 884px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cccccc;

    .password {

      // text-align: left;
      p:nth-child(1) {
        // align-self: left;
        text-align: left;

        color: cadetblue;
      }
    }

    span {
      // align-self: flex-end;
    }
  }

  .Mobile {
    display: flex;
    width: 884px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cccccc;

    .Tel {
      p:nth-child(1) {
        text-align: left;
        color: cadetblue;
      }
    }
  }

  .sendyzm {
    width: 100px;
    height: 40px;
    margin-left: 20px;
    float: left;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border: 1px solid #cccccc;
  }

  .sendTelyzm {
    width: 100px;
    height: 40px;
    float: left;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #cccccc;
  }

  .bing_sendyzm {
    width: 100px;
    height: 40px;
    margin-left: 20px;
    float: left;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border: 1px solid #cccccc;
  }

  .bing_sendTelyzm {
    width: 100px;
    height: 40px;
    float: left;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #cccccc;
  }
</style>
