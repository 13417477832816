<template>
	<el-dialog title="上传资质" :visible="true" width="40%" :before-close="onCancel">
		<el-form ref="form" :model="form" label-width="120px" :rules="rules">
			<el-form-item label="推送标题" prop="push_title" required>
				<el-input v-model="form.push_title" placeholder="请输入推送标题，最多可输入6个字"></el-input>
			</el-form-item>
			<el-form-item label="资质类型" prop="type" required>
				<el-select v-model="form.type" placeholder="请选择资质类型" style="width:100%" :disabled="editMode">
					<el-option v-for="item in certTypeOptions" :key="item" :label="item" :value="item">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="证照有效期" required>
				<el-form-item prop="period">
					<div style="display:flex;">
						<div style="margin-right:20px;">
							<el-date-picker :disabled="checked" format="yyyy-MM-dd" type="date"
								value-format="yyyy-MM-dd" placeholder="请选择认证资质的截止日期" v-model="form.period"
								style="width: 100%;"></el-date-picker>
						</div>
						<div>
							<el-checkbox v-model="checked">长期</el-checkbox>
						</div>
					</div>
				</el-form-item>
			</el-form-item>
			<el-form-item label="证照上传" prop="img" required>
				<div style="text-align:left">
					<el-upload :headers="headers" :file-list="imgList" action="http://shiyun.dljianwei.com/upload/img"
						list-type="picture-card" :on-remove="onRemovePic" :on-success="onSuccess">
						<i class="el-icon-plus"></i>
						<div class="el-upload__tip" slot="tip">图片大小：800×200，支持jpg、jpeg、png格式，单张图片不大于1M</div>
					</el-upload>
				</div>
			</el-form-item>
			<el-form-item>
				<el-button @click="onCancel">取消</el-button>
				<el-button type="primary" @click="onSave">保存</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import {
		addAdvQualifications,
		editAdvQualifications
	} from '@/api/agentApi.js'
	export default {
		props: {
			certTypeOptions: {
				type: Array,
				default: []
			},
			data: {
				type: Object,
				default: null
			}
		},
		emits: ['cancel', 'ok'],
		data() {
			return {
				editMode: false,
				imgList: [],
				checked: false,
				form: {
					id: '',
					push_title: '',
					type: '',
					period: '',
					img: ''
				},
				headers: {
					token: localStorage.getItem('token')
				},
				rules: {
					push_title: [{
							required: true,
							message: '请输入推送标题',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 6,
							message: '长度在 1 到 6 个字符',
							trigger: 'blur'
						}
					],
					type: [{
						required: true,
						message: '请选择资质类型',
						trigger: 'blur'
					}],
					period: [{
						required: true,
						message: '请选择证照有效期',
						trigger: 'blur'
					}],
					img: [{
						required: true,
						message: '请上传证照图片',
						trigger: 'blur'
					}]
				}
			}
		},
		watch: {
			checked(val) {
				if (!val && this.form.period == 1) {
					this.form.period = ''
				}
				if (val && !this.form.period) {
					this.form.period = '1'
				}
			},
			imgList(val) {
				let str = ''
				val.forEach(item => {
					str += str ? ',' + item.url : item.url
				})
				this.form.img = str
				console.log(this.form)
			}
		},
		mounted() {
			if(this.data) {
				this.editMode = true
				this.form = {...this.data}
				if (this.form.period == 1) this.checked = true
				this.imgList = this.form.img.split(',').map(item => {
					return {
						name: '',
						url: item
					}
				})
			}
		},
		methods: {
			onSuccess(response) {
				this.imgList.push({
					name: '',
					url: response.data.url
				})
			},
			onRemovePic(file, fileList) {
				this.imgList = fileList
			},
			onCancel() {
				this.$emit('cancel')
			},
			onSave() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						if (this.checked) this.form.period = '1'
						if (this.form.id) {
							editAdvQualifications(this.form).then(res => {
								this.form = {
									id: '',
									push_title: '',
									type: '',
									period: '',
									img: ''
								}
								this.$emit('ok')
							})
						} else {
							addAdvQualifications(this.form).then(res => {
								this.form = {
									id: '',
									push_title: '',
									type: '',
									period: '',
									img: ''
								}
								this.$emit('ok')
							})
						}
					}
				})
			}
		}
	}
</script>

<style>
</style>