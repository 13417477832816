<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>教师列表</p>
        <el-button type="primary" class="btn" @click="addTeacher">+ 添加教师</el-button>
      </div>
      <div class="query">
        <div class="queryLeft">
          <div>姓名：
            <el-input class="input" v-model.trim="serchList.name" size="medium" placeholder="请输入教师姓名"></el-input>
          </div>
          <!--          <div>姓名：
            <el-select class="select" v-model="serchList.name" @change="TeacherList(serchList,1)" size="medium"
              placeholder="请选择姓名" filterable>
              <el-option v-for="item in ChargeLists" :key="item.TeaUserID" :label="item.TeaName"
                :value="item.TeaUserID">
              </el-option>
            </el-select>
          </div>
          <br>
          <div>性别：
            <el-select class="select" v-model="serchList.sex" @change="TeacherList(serchList,1)" size="medium"
              placeholder="请选择性别">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>-->
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="teacherSearch(serchList,1)">查询</el-button>
        </div>
      </div>

      <el-table :data="teacherInfos" v-loading="loading">
        <el-table-column prop="TeaName" label="姓名" align="center" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.ClassID >0" type="text" size="small" @click="TeaNames(scope.$index, scope.row)">
              {{scope.row.TeaName}}
            </el-button>
            <span v-else size="small">{{scope.row.TeaName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="TeaGender" label="性别" :formatter="Gender" align="center"></el-table-column>
        <el-table-column prop="" label="是否认证人脸" align="center">
          <template slot-scope="scope">
            <el-image v-if="scope.row.facepic" style="width: 160px; height: 90px" alt="认证照片" :src="scope.row.facepic"
              :preview-src-list="[scope.row.facepic]"></el-image>
            <span v-else>未认证</span>
          </template>
        </el-table-column>
       <el-table-column prop="createtime" label="实名认证时间" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime">{{scope.row.createtime}}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column prop="ClassName" label="教职工岗位" align="center">
          <template slot-scope="scope">
            <span v-if="!!scope.row.TypeName">{{scope.row.TypeName + scope.row.ClassName+scope.row.typename}}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column prop="Mobile" label="联系方式" align="center"></el-table-column>
        <el-table-column prop="InsertTime" label="添加时间" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="300">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain v-if="scope.row.ClassName==null?false:true"
              @click="changeClass(scope.$index, scope.row)">修改班级</el-button>
            <el-button size="mini" type="primary" plain v-if="scope.row.ClassName==null?true:false"
              @click="joinClass(scope.$index, scope.row)">加入班级</el-button>
            <el-button size="mini" type="primary" @click="onEditName(scope.row)">修改姓名
            </el-button>
            <el-button size="mini" type="primary" @click="teacherDel(scope.row)">移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-show="teacherInfos.length > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
    <el-drawer title="班级列表" :visible.sync="Clasdrawer" direction="rtl" size="15%">
      <el-table :data="ClassLists" height="800">
        <el-table-column property="ClassName" label="班级" width="150">
          <template slot-scope="scope">
            {{scope.row.TypeName + scope.row.ClassName}}
          </template>
        </el-table-column>
        <el-table-column label="">
          <template slot-scope="scope" v-if="scope.row.TeaName==null?true:false">
            <el-button size="mini" type="primary" @click="choice(scope.$index, scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <el-dialog title="修改班级" :visible.sync="Cladialog" top="30px">
      <span>是否指定<p style="color:#1890FF;display:inline">{{TeaName}}</p> 成为 <p style="color:#1890FF;display:inline">
          {{ClassNames}}
        </p> 的班主任?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Cladialog = false">取 消</el-button>
        <el-button type="primary" @click="affirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加教师" :visible.sync="teacherDialog" width="40vw" :close-on-click-modal="false" @closed="closeteacherDialog()">
      <p>教师注册手机号：
        <el-input v-model.trim="teacherName"  placeholder="请输入教师注册手机号" maxlength="11" style="width: 20vw;"></el-input>
        &nbsp;&nbsp;
        <el-button type="primary" @click="addSearchf">查询</el-button>
      </p>

      <div v-if=" this.addtea == 1" style="height: 135px;margin: 0 165px;">
        <el-image style="width: 100px; height: 100px;float: left;margin-right: 20px;" :src="addteaList.HeadImageUrl"
          fit="contain"></el-image>
        <span>{{addteaList.GuaName}}</span>
        <el-input v-model.trim="teacherobj.name" placeholder="请输入教师姓名" style="width: auto;display: flow-root;">
        </el-input>
        <el-select v-model="teacherobj.type" clearable placeholder="请选择职位" style="margin-top: 6px; width: auto;display: flow-root;">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

      </div>
      <el-button v-if=" this.addtea == 1" type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog title="修改姓名" :visible.sync="teacherNameDialog" width="40vw" :close-on-click-modal="false">
      <p>教师姓名：
        <el-input v-model.trim="editName"  placeholder="姓名" maxlength="11" style="width: 20vw;"></el-input>
        &nbsp;&nbsp;
        <el-button type="primary" @click="onConfirmEditName">提交</el-button>
      </p>

    </el-dialog>
  </div>
</template>

<script>
  // import Json from '../../../mock.js'
  import {
    teacherList,
    SchoolClassList,
    editClass,
    teacherAdd,
    teaDel,
    teacherNameEdit,
    addTeacherSearch
  } from '@/api/user.js'
  export default {
    data() {
      return {
        teacherNameDialog:false,
        editName: '',
        teacherInfos: [],
        ClassLists: [], // 班级列表
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        Cladialog: false, // 修改班级弹窗
        teacherDialog: false, //添加教师弹窗
        Clasdrawer: false, // 班级列表抽屉
        loading: false, //加载框
        teacherName: '', //添加教师名字
        TeaName: '', // 教师名
        ClassNames: '', // 班级名
        GradeID: '', // 年级ID
        ClassID: '', // 班级ID
        TeacherID: '', // 教师ID
        ClassName: '', // 班级名称
        TypeName: '', // 班级名称
        ChargeLists: [], // 姓名列表
        serchList: {}, //查询条件
        sexList: [{ // 性别选项
          value: '1',
          label: '男'
        }, {
          value: '2',
          label: '女'
        }],
        ttype: 0, //加入班级 为1  修改班级为3
        oldTypeName: '', //旧年级名称
        oldClassName: '', //旧班级名称
        searchName: '', //搜索姓名
        addSearch: '', //添加教师时查询
        addtea: 0,
        addteaList: {}, //查询教师数组
        teacherobj: {
          name :"",
          type :"",
        }, //添加教师对象
        options: [{
          value: '1',
          label: '教师'
        },
        // {
        //   value: '2',
        //   label: '双皮奶'
        // }, {
        //   value: '3',
        //   label: '蚵仔煎'
        // }, {
        //   value: '4',
        //   label: '龙须面'
        // }, {
        //   value: '5',
        //   label: '北京烤鸭'
        // },
        ],
      }
    },
    mounted() {
      this.TeacherList({}, 1) // 教师列表
      this.ClassList() // 班级列表
      this.teacherList() //姓名列表
      //  this.teacherInfos  = Json.teacherList;
    },
    methods: {
      onEditName(row) {
        this.TeacherID = row.TeaUserID
        this.editName = row.TeaName
        this.teacherNameDialog = true
      },
      onConfirmEditName() {
        teacherNameEdit({
          teacher_id: this.TeacherID,
          teacher_name: this.editName
        }).then(res=>{
          this.teacherNameDialog = false
          this.TeacherList({}, 1)
        })
      },
      reset() { //重置按钮
        this.searchName = '' //清空搜索项
        this.serchList = {} //清空搜索项
        this.TeacherList({}, 1) //获取班级列表
      },
      changeClass(index, row) { // 修改班级
        // console.log(row)
        this.Clasdrawer = true
        this.TeacherID = row.TeaUserID // 教师ID赋值
        this.TeaName = row.TeaName // 教师名字赋值
        this.ttype = 3 // 教师名字赋值
        this.oldTypeName = row.TypeName // 旧年级名称
        this.oldClassName = row.ClassName // 旧班级名称
      },
      Gender(row, column) { // 性别筛选
		const genderOptions = {
			'1': '女',
			'2': '男',
			'3': '保密'
		}
        return genderOptions[row.TeaGender]
      },
      joinClass(index, row) { // 加入班级
        this.Clasdrawer = true
        this.TeacherID = row.TeaUserID // 教师ID赋值
        this.TeaName = row.TeaName // 教师名字赋值
        this.ttype = 1 // 教师名字赋值
        console.log(row)
      },
      affirm() { // 点击确定

        if (this.ttype == 1) {
          this.oldTypeName = '';
          this.oldClassName = '';
        }

        const data = {
          classID: this.ClassID, // 班级ID
          classType: this.GradeID, // 年级ID
          ClassName: this.ClassName, // 班级名称
          TypeName: this.TypeName, // 年级名称
          teacher: this.TeacherID, // 班主任ID
          type: this.ttype, // 后台传入类型
          oldTypeName: this.oldTypeName, //旧年级名称
          oldClassName: this.oldClassName, //旧班级名称
          newteacher: this.TeaName,
        }

        editClass(data).then(res => {
          // console.log(res)
          if (res.status.code == 1) {
            this.$message({
              message: '指定成功',
              type: 'success'
            })
            this.Cladialog = false
            this.Clasdrawer = false
            this.TeacherList({}, 1) // 刷新

            this.ClassList() // 刷新
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      choice(index, row) { // 点击选择
        // console.log(row)

        this.GradeID = row.ClassType // 年级ID赋值
        this.ClassID = row.ID // 班级ID赋值
        this.ClassName = row.ClassName // 班级名称赋值
        this.TypeName = row.TypeName // 年级名称赋值
        this.ClassNames = row.TypeName + row.ClassName // 选择班级名字赋值
        this.Cladialog = true
      },
      addTeacher() { // 添加教师按钮
        this.teacherDialog = true
        this.teacherName = ''
      },
      addSearchf() { //添加教师弹查询按钮

        if (this.teacherName == "") {
          return false
        }

        const data = {
          tel: this.teacherName
        }
        addTeacherSearch(data).then(res => {
          if (res.status.code == 1) {
            this.addteaList = res.data
            this.addtea = 1
            this.teacherobj.tel = this.teacherName
          } else{
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          this.$message.error('改手机号已添加过学校')
        })
      },
      add() { //添加教师弹框添加按钮

        if(this.teacherobj.name == ""){
            this.$message.error('请填写教师姓名')
            return false;
        }
        if(this.teacherobj.type == ""){
            this.$message.error('请选择职位')
            return false;
        }

        const data = {
          RegisterMobile: this.teacherobj.tel,
          remark_name   : this.teacherobj.name,
          type          : this.teacherobj.type
        }

        teacherAdd(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.teacherName   = '' // 清空输入框
            this.teacherobj    = {} // 清空
            this.teacherDialog = false
            this.addtea        = 0 // 清空输入框
            this.TeacherList({}, 1) // 教师列表
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      ClassList() { // 获取班级列表
        const data = {
          page: 1,
          limit: 500,
          gid: '',
          cid: ''
        }
        SchoolClassList(data).then(res => {
          // console.log(res)
          this.ClassLists = res.data.List
        }).catch(err => {
          console.log(err)
        })
      },
      TeacherList(serchJson, currpage) { // 获取教师列表
        this.loading = true
        const data = serchJson //  查询条件
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["search"] = this.searchName // 查询条件
        teacherList(data).then(res => {
          this.teacherInfos = res.data.List
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.currpage = currpage // 分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      teacherSearch(obj, val) { //只有当点击查询时候走这个方法

        this.searchName = obj.name == undefined ? '' : obj.name

        const data = {
          page: val,
          limit: this.pagesize,
          search: this.searchName
        }

        teacherList(data).then(res => {
          this.teacherInfos = res.data.List
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.currpage = currpage // 分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      teacherList() { // 姓名列表
        const data = {
          page: 1,
          limit: 300
        }
        teacherList(data).then(res => {
          this.ChargeLists = res.data.List
        }).catch(err => {
          console.log(err)
        })
      },
      TeaNames(index, row) { // 点击跳转教师首页
        this.$router.push({
          name: 'teacherHome',
          params: {
            ClassID: row.ClassID
          }
        })
      },
      handleCurrentChange(val) {
        this.TeacherList({}, val)
        // this.currpage = val
      },
      teacherDel(row) { // 移除教师
        this.$confirm('此操作将从学校移除教师, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const data = {
            id      : row.TeaUserID, // 教师D
            classid : row.ClassID,  // 班级ID
            teaname : row.TeaName,  // 教师姓名
          }
          teaDel(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '移除成功',
                type: 'success'
              })
              if (this.teacherInfos.length == 1) {
                if (this.currpage > 1) {
                  this.TeacherList({}, this.currpage - 1) //获取班级列表
                } else {
                  this.TeacherList({}, this.currpage) //获取班级列表
                }
              } else {
                this.TeacherList({}, this.currpage) //获取班级列表
              }
              this.ClassList() // 班级列表
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消移除'
          });
        });
      },
      closeteacherDialog() {
        this.addteaList = [];
        this.addtea      = 0;
      },
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 70%;
        display: flex;

        div {
          margin-right: 20px;
          width: 35%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
