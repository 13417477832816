<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>数据列表</p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button type="primary" @click="(dialogVisibles = true)">导出数据</el-button>
      </div>
      <div class="query">
        <div class="queryLeft">
            <div style="float: left ;width: fit-content;margin-right: 10px;" v-if=" Status == 'school' ? true :false">
                创建时间:
                <el-select v-model="ctime" clearable style="width: 150px;">
                <el-option v-for="(item,index) in ctimeList" :key="index" :label="item.year" :value="item.ctime">
                </el-option>
                </el-select>
            </div>&nbsp;&nbsp;
            <div style="float: left ;width: fit-content;margin-right: 10px;" v-if=" Status == 'school' ? true :false">
                毕业班号:
                <el-select v-model="oyear" clearable style="width: 150px;">
                <el-option v-for="(item,index) in oyearList" :key="index" :label="item.jie" :value="item.oyear">
                </el-option>
                </el-select>
            </div>&nbsp;&nbsp;
            <div>
              <el-button @click="reset()">重置</el-button>
            <el-button type="primary" @click="studentSearchfirst(1)">查询</el-button>
            </div>
        </div>
      </div>
      <el-table :data="ClassInfos" v-loading="loading">
        <el-table-column label="创建时间" prop="ctime" width="150" align="center"></el-table-column>
        <el-table-column label="毕业班号" prop="ClassName" width="150" align="center"></el-table-column>
        <el-table-column label="历任班主任" width="250" align="center">
          <template slot-scope="scope">
            <el-tooltip  v-if ="scope.row.tnum > 0">
              <div slot="content">
                <div v-for="(item,index) in scope.row.tealist" :key="index">
                    <div>  {{item.insert_time}} - {{item.over_time}}
                      <span style="margin-left:5px;"> {{item.teacher_name}}</span>
                    </div>
                </div>
              </div>
              <el-button type="text"  >{{scope.row.tnum}}</el-button>
            </el-tooltip>
            <span v-else> {{scope.row.tnum}} </span>
          </template>
        </el-table-column>
        <el-table-column label="学生人数(MAX)" prop="ftnum" width="150" align="center"></el-table-column>
        <el-table-column label="学生人数(MIN)" prop="ltnum" width="150" align="center"></el-table-column>
        <el-table-column label="创建时近视率" prop="ftlhv"  align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.ftlhv !=''"><span>{{scope.row.ftlhv}}%</span></div>
            </template>
        </el-table-column>
        <el-table-column label="结束时近视率" prop="ltlhv" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.ltlhv !=''"><span>{{scope.row.ltlhv}}%</span></div>
            </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="doView(scope.$index, scope.row)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    <el-dialog
      :visible.sync="dialogVisibles"
      title='导出数据'
      width="500px"
    >
      <div>
        <el-form label-width="80px" class="demo-ruleForm" label-position="left">
          <el-form-item label="毕业班号" >
            <el-select v-model="form.oyear" clearable style="width: 350px;">
                <el-option v-for="(item,index) in oyearList" :key="index" :label="item.jie" :value="item.oyear">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期" >
            <el-date-picker
             style="width: 350px;"
              v-model="form.day"
              value-format="yyyy-MM-dd"
              type="date"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="请选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibles = false">取 消</el-button>
          <el-button type="primary" @click="doExport">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  classHistoryCtime,
  classHistoryYear,
  classHistory,
  schoolGoodPic,
  excelDaochueye
} from '@/api/SchoolApi.js'

import {
  mapGetters
} from 'vuex'
export default {
  computed: {
    ...mapGetters(['Status'])
  },
  data () {
    return {
      ClassInfos: [],
      loading: false, // 加载框
      pagesize: 12, // 每页显示条目个数
      currpage: 1, // 初始页
      CountNum: 0, // 总条数
      ctime: '', // 创建时间
      ctimeList: [], // 创建时间列表
      oyear: '', // 毕业班号
      oyearList: [], // 毕业班号列表
      schoollhv: '',
      arealhv: '',
      Series: [], // 折线图Y轴数据
      Dates: [], // 折线图X轴数据
      form: {
        day: '',
        oyear: '', // 毕业班号
        bstatus: 2
      },
      dialogVisibles: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    }
  },
  mounted () {
    this.ClassList(1) // 获取班级列表
    this.getCtimeList() // 获取创建时间列表
    this.getOyearList() // 获取毕业班号列表
  },
  methods: {
    doExport () { // 导出

      if(this.form.day == "" ||  this.form.day==null){
          this.$message.error('请选择日期')
          return false;
      }
      excelDaochueye(this.form).then(res => {
        if (res.status.code == 1) {
          window.open(Vue.prototype.$url + res.data)
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getCtimeList () {
      this.loading = true
      classHistoryCtime().then(res => {
        this.ctimeList = res.data
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    getOyearList () {
      this.loading = true
      classHistoryYear().then(res => {
        this.oyearList = res.data
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    reset () { // 重置按钮
      this.ctimeList = []
      this.oyearList = []
      this.ctime = ''
      this.oyear = ''
      this.currpage = 1
      this.ClassList(this.currpage)
      this.getCtimeList() // 获取创建时间列表
      this.getOyearList() // 获取毕业班号列表
    },
    ClassList (currpage) { // 班级数据管理列表
      this.loading = true
      const data = {
        page: currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        ctime: this.ctime,
        oyear: this.oyear
      }
      classHistory(data).then(res => {
        // console.log(res.data.List)
        this.ClassInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum) // 总条数
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    // 查看详情
    doView (index, row) {
      this.$router.push({ // 页面跳转
        name: 'hisclassStudentsData', // 往这个页面跳转
        params: { // 跳转传递的参数
          cid: row.ID,
          oyear: row.oyear
        }
      })
    },
    handleCurrentChange (val) {
      if (this.gradeid == '') {
        this.ClassList(val)
        this.currpage = val
      } else {
        this.studentSearch(val) // 当有姓名条件时 分页操作
      }
    },
    studentSearchfirst (val) { // 只有当点击查询时候走这个方法
      const data = {
        page: val,
        limit: this.pagesize,
        ctime: this.ctime,
        oyear: this.oyear
      }
      classHistory(data).then(res => {
        this.currpage = 1
        this.ClassInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum)
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },

    studentSearch (val) { // 当第一次 点了查询条件后 分页走这个方法
      this.currpage = val
      const data = {
        page: this.currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        ctime: this.ctime,
        oyear: this.oyear
      }

      classHistory(data).then(res => {
        this.ClassInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum)
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    drawChart () {
      const that = this
      const myChart = this.$echarts.init(document.getElementById('main'))
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['近视率']
        },
        xAxis: {
          data: that.Dates
        },
        yAxis: {},
        series: [{
          name: '近视率',
          type: 'line',
          data: that.Series
        }]
      }
      myChart.setOption(options)
    },
    schoolEchart () { // 全校近视率-折线图
      this.dialogVisible = true
      schoolGoodPic().then(res => {
        // console.log(res)
        this.Series = res.data.jnum
        this.Dates = res.data.date
        this.drawChart()
      }).catch(err => {
        console.log(err)
      })
    }

  }

}
</script>

<style lang="scss" scoped>
  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
  .dataBox{
    padding:5px 20px;
    font-size:18px;
    cursor:pointer;
    .num{
      color:#409EFF
    }
  }
</style>
