<template>
  <div id="body">
    <div class="fatherBox">
      <div class="header">
        <div class="headerBox" style="display: block;height: 90px;">
          <p style="text-align: left;"><span class="stutitle">修改学生信息</span></p>
          <p style="text-align: left;"><span style="color:#cbcb42;cursor: pointer;"
              @click="toStulist()">学生管理</span>/<span style="color:#cbcb42;cursor: pointer;"
              @click="toStude()">{{ stuData.ystuname }}</span>/<span>修改信息</span></p>
        </div>
      </div>
      <div class="stubody" style="margin:25px 40px 10px 10px;">
        <div style="text-align: left;width: 93%;">
          <template>
            <div style="width: 100%;">
              <div class="tt">学生信息</div>
              <form action="">
                <table border="0px" cellspacing="" cellpadding=""
                  style="width: 60%;text-align: left;padding-bottom: 80px;margin: 10px;padding-bottom: 10px;">
                  <tr>
                    <td><span class="tabtit">姓名：</span>
                      <el-input v-model="stuData.xstuname" class="titinput" placeholder="请填写姓名" @input="change($event)">
                      </el-input>
                    </td>
                    <td><span class="tabtit">班级：</span>
                      <el-select v-model="stuData.xgrade" @change="classSelectChange()" clearable style="width: 150px;"
                        @input="change($event)">
                        <el-option v-for="(item,index) in gradeList" :key="index" :label="item.TypeName"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <el-select v-model="stuData.xclass" clearable style="width: 150px;" @input="change($event)">
                        <el-option v-for="(item,index) in classList" :key="index" :label="item.ClassName"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="tabtit">性别：</span>
                      <el-radio v-model="stuData.xgender" label="1" @input="change($event)">男</el-radio>
                      <el-radio v-model="stuData.xgender" label="0" @input="change($event)">女</el-radio>
                    </td>
<!--                    <td><span class="tabtit">出生年月：</span>
                      <el-date-picker v-model="BirthdayDate" class="titinput" disabled="disabled" type="date"
                        placeholder="选择日期">
                      </el-date-picker>
                    </td> -->
                    <td><span class="tabtit">学号：</span>
                      <el-input v-model="stu_id" class="titinput"  placeholder="请填写学号">
                      </el-input>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="tabtit">学生编号：</span>
                      <el-input v-model="StuNumber" class="titinput" disabled="disabled" placeholder="请填写学生编号">
                      </el-input>
                    </td>
                    <td><span class="tabtit">短编号：</span>
                      <el-input v-model="short_num" class="titinput" disabled="disabled" placeholder="请填写学生编号">
                      </el-input>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: text-top;"><span class="tabtit">加入时间：</span>
                      <el-date-picker v-model="insertTime" class="titinput" type="date" disabled="disabled"
                        placeholder="选择日期">
                      </el-date-picker>
                    </td>
                    <td>
                      <span class="tabtit" style="float: left;">图像认证：</span>
                      <!--      <img :src="stuData.ImageUrl" alt="" style="float:left;width:100px;"> -->
                      <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1"
                        :on-progress="uploading" :file-list="imgList" accept="image/jpg,image/png,image/jpeg"
                        :on-remove="removeImg" :headers="{'token':token}" :on-success="fileSuccess" :on-error="imgError"
                        :on-exceed="limitAlert" style="height: 145px;">
                        <i slot="default" class="el-icon-plus"></i>
                      </el-upload>
                    </td>
                  </tr>
                </table>
                <p style="width: 60%;text-align: center;">
                  <el-button type="primary" @click="onSubmit()">保存</el-button>
                </p>
              </form>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    teastuinfo,
    gradeClass,
    editstu,
  } from '@/api/TeacherApi.js';
  import {
    mapGetters
  } from "vuex";
  export default {
    computed: {
      ...mapGetters(["Status"]),
    },
    data() {
      return {
        stuUserID: '',
        dstatus: false,
        stuData: {},
        activeName: 'first',
        guaData: [],
        loading: false,
        guaStart: 0,

        gradeList: [],
        classList: [],
        classid: '',
        imgList: [],

        imgData: [], //上传服务器图片数组
        // imgurl: this.$url + 'upload/imgFace', //上传人脸请求地址(本地)
        imgurl: this.$url + '/upload/imgFace', //上传人脸请求地址(线上)
        imgList: [],
        image: '',
        file: [], //上传的file数组
        token: localStorage.getItem('token'), //token


        BirthdayDate: '',
        StuNumber: '',
        stu_id: '',
        short_num: '',
        insertTime: '',
        // StuUserID :'',	//学生ID
        // ystuname :'',	//原来学生姓名
        // ygender :'',	//原来学生性别
        // ygradename :'',	//原来年级名称
        // yclassname :'',	//原来班级名称
        // image :'',	//头像url
        // xstuname :'',	//学生姓名修改
        // xgender :'',	//性别修改
        // xgrade :'',	//年级修改ID
        // xclass :'',	//班级修改ID

      }
    },
    mounted() {
      this.stuUserID = this.$route.params.StuID // 给学生ID赋值 通过上个页面$route传递过来的
      this.StuInfo(); //获取学生详细信息
      // this.StudentList(1) // 获取学生列表
      // this.gradeListvue() // 获取年级信息
    },
    methods: {
      change() {
        this.$forceUpdate()
      },
      toStulist() {
        this.$router.push({ //页面跳转
          name: 'studentList', //往这个页面跳转
          params: { //跳转传递的参数
          }
        })
      },
      toStude() {
        this.$router.push({ //页面跳转
          name: 'studetail', //往这个页面跳转
          params: { //跳转传递的参数
            StuID: this.stuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
          }
        })
      },
      StuInfo() {
        const data = {
          stuUserID: this.stuUserID, //
        }
        teastuinfo(data).then(res => {

          this.BirthdayDate = res.data.BirthdayDate
          this.StuNumber = res.data.StuNumber
          this.stu_id = res.data.stu_id
          this.short_num = res.data.short_num
          this.insertTime = res.data.insertTime

		if (res.data.ImageUrl) {
          let data = {
            imgId: 111,
            url: res.data.ImageUrl
          }
          this.imgList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给imgData
		  } else {
			  this.imgList = []
		  }

          this.stuData.StuUserID = this.stuUserID; //学生ID
          this.stuData.ystuname = res.data.StuName; //原来学生姓名
          this.stuData.ygender = res.data.StuGender; //原来学生性别
          this.stuData.ygradename = res.data.TypeName; //原来年级名称
          this.stuData.yclassname = res.data.ClassName; //原来班级名称
          this.stuData.image = res.data.ImageUrl; //头像url
          this.stuData.xstuname = res.data.StuName; //学生姓名修改
          this.stuData.xgender = res.data.StuGender; //性别修改
          this.stuData.xgrade = res.data.Classtype; //年级修改ID
          this.stuData.xclass = res.data.ClassID; //班级修改ID

          //赋值结束  进行班级数据渲染
          this.gradeListvue() // 获取年级信息
          this.classSelect() // 获取班级信息

        }).catch(err => {
          console.log(err)
        })
      },
      gradeListvue() { // 年级列表
        const data = {
          type: 1 //没用
        }
        gradeClass(data).then(res => {

          this.gradeList = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      classSelect() { // 班级列表
        const data = {
          gid: this.stuData.xgrade
        }

        if (this.stuData.xgrade != "") {
          gradeClass(data).then(res => {
            this.classList = res.data;
          }).catch(err => {
            console.log(err)
          })
        }

      },
      classSelectChange() { // 班级列表
        this.stuData.xclass = ''
        const data = {
          gid: this.stuData.xgrade
        }

        if (this.stuData.xgrade != "") {
          gradeClass(data).then(res => {
            this.classList = res.data;
          }).catch(err => {
            console.log(err)
          })
        }

      },

      uploading(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.imgList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.imgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlert() { //展示图片超过张数提醒
        this.$message.error('修改照片需要先删除当前照片')
      },
      imgError(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImg(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.stuData.image = '';
        this.imgData = this.imgData.filter(item => item.imgId != file.uid) //从imgData 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccess(response, file, fileList) { //展示图片上传成功
        if (response.data.err == 1) {
          this.$message.error('未检测到人脸！')
          this.imgList = [];
        } else {
          this.$message.success('图片上传成功！')
          this.stuData.image = response.data.url;
          this.stuData.facefeature = response.data.facefeature;
        }

      },
      onSubmit() {
        // if (this.stuData.image == "") {
        //   this.$message.error('请上传人脸！')
        //   return false;
        // }
        const data = {
          stuUserID: this.stuData.StuUserID, //学生ID
          stu_id: this.stu_id, //学生ID
          ystuname: this.stuData.ystuname, //原来学生姓名
          ygender: this.stuData.ygender, //原来学生性别
          ygradename: this.stuData.ygradename, //原来年级名称
          yclassname: this.stuData.yclassname, //原来班级名称
          image: this.stuData.image, //头像url
          xstuname: this.stuData.xstuname, //学生姓名修改
          xgender: this.stuData.xgender, //性别修改
          xgrade: this.stuData.xgrade, //年级修改ID
          xclass: this.stuData.xclass, //班级修改ID
        }
		
		if (this.stuData.facefeature) data.facefeature = this.stuData.facefeature

        if (data.xgrade == "") {
          this.$message.error('请选择年级')
          return false;
        }
        if (data.xclass == "") {
          this.$message.error('请选择班级')
          return false;
        }
        // if (data.xstuname == "") {
        //   this.$message.error('请填写姓名')
        //   return false;
        // }

        editstu(data).then(res => {
          if (res.status.code == 1) {
            this.$message.success('信息修改成功！')
            this.$router.push({ //页面跳转
              name: 'studetail', //往这个页面跳转
              params: { //跳转传递的参数
                StuID: this.stuUserID, //这个StuID 是router文件下 	index.js path: 'eyesight/:StuID?', 必须要传
              }
            })

          } else {
            this.$message.success('错误信息是：' + res.status.msg + ' 当前错误码：' + res.status.code)
          }

        }).catch(err => {
          console.log(err)
        })


      }
    }

  }
</script>

<style lang="scss" scoped>
  //新样式
  .titinput {
    width: 300px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .stutitle {
    font-size: 30px !important;
  }

  .tt {
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 500;
  }

  .tabtit {
    width: 100px;
    display: inline-block;
    text-align: right;
  }

  tr {
    line-height: 45px;
  }

  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
